import { globalStore, useGlobalStore } from "@/store/GlobalStore";
import React from "react";
import { GridSettings } from "./GridSettings";
import { MeasureSettings } from "./MeasureSettings";
import { useTranslation } from "react-i18next";

export const OptionsMenu = () => {
  const state = useGlobalStore((s) => s);
  const { t } = useTranslation();

  return (
    <>
      {/* <pre style={{ fontSize: ".6em" }}>{JSON.stringify(state, null, 2)}</pre>
      <hr /> */}
      <label>
        <input
          type="checkbox"
          checked={state.poleVisibility}
          onChange={(e) => {
            globalStore.setPoleVisibility(e.target.checked);
          }}
        />
        {t("optionsMenu.showPoleLine")}
      </label>
      <br />

      <GridSettings />
      <MeasureSettings />
      <label>{t("optionsMenu.lightIntensity")}</label>
      <br />
      <input
        type="range"
        id="sunIntensity"
        value={state.lightIntensity}
        min="0"
        max="5"
        step="0.01"
        onChange={(e) => {
          globalStore.setLightIntensity(parseFloat(e.target.value));
        }}
      />
      <br />
      {CONFIG.heightMap && (
        <div id="heightOptions">
          <label>{t("optionsMenu.heightmapScale")}</label>
          <br />
          <input
            type="range"
            id="heightScale"
            value={state.bumpScale}
            min="0"
            max="50"
            step="1"
            onChange={(e) => {
              globalStore.setBumpScale(parseFloat(e.target.value));
            }}
          />
        </div>
      )}
    </>
  );
};
