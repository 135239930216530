import { useTranslation } from "react-i18next";
import "./LanguageSelector.scss";
import React, { ChangeEvent, useCallback } from "react";
import { LANGUAGES } from "@/translations";
import { translationStore, useTranslationStore } from "@/translations/state/TranslationState";

export const LanguageSelector = () => {
  const { t } = useTranslation();
  const language = useTranslationStore((s) => s.language);

  const onLanguageChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    translationStore.setLanguage(e.target.value);
  }, []);

  return (
    <div className="mm-language-selector">
      <label>{t("translations.language")}:</label>
      <select id="language-select" value={language} onChange={onLanguageChange}>
        {LANGUAGES.map((l) => (
          <option key={l} value={l}>
            {t(`translations.names.${l}`)}
          </option>
        ))}
      </select>
    </div>
  );
};
