export type CollectionMap = { id: string; image: string };
export type CollectionFragment = { id: string; image: string; metadata: Record<string, string> };

export const getMaps = async (collectionName: string): Promise<CollectionMap[]> => {
  return await fetch(`api/v1/collections/${collectionName}/maps`).then((r) => r.json());
};

export const getFragments = async (collectionName: string, mapId: string): Promise<CollectionFragment[]> => {
  return await fetch(`api/v1/collections/${collectionName}/maps/${mapId}/fragments`).then((r) => r.json());
};
