"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGlobalStore = exports.globalStore = exports.GlobalStore = void 0;
const ProjectionOptions_1 = require("@/graphics/projection/ProjectionOptions");
const BaseStore_1 = require("./BaseStore");
const tsyringe_1 = require("tsyringe");
let GlobalStore = class GlobalStore extends BaseStore_1.BaseStore {
    constructor() {
        super({
            activeView: "globe",
            mapUrls: [],
            mapUrlLoadingState: {},
            mapInfo: null,
            heightmapUrl: window.location.origin + "/MarsDEM.png",
            poleVisibility: false,
            mouseCoordinate: null,
            measure: {
                enabled: false,
                radius: CONFIG.radius,
                coordinateA: null,
                coordinateB: null,
            },
            graticule: {
                visible: false,
                meridianIntervalDegrees: 10,
                parallelIntervalDegrees: 10,
                color: "#FFFFFF",
            },
            lightIntensity: 1,
            bumpScale: CONFIG.heightMap ? 1 : 0,
            projection: {
                optionIndex: ProjectionOptions_1.DEFAULT_PROJECTION_OPTION_INDEX,
            },
        });
    }
    setActiveView(activeView) {
        this.setState((s) => (Object.assign(Object.assign({}, s), { activeView })));
    }
    setMap(mapUrls, mapInfo) {
        this.setState((s) => (Object.assign(Object.assign({}, s), { mapUrls, mapInfo })));
    }
    setMapUrlLoadingState(mapUrl, progress) {
        this.setState((s) => (Object.assign(Object.assign({}, s), { mapUrlLoadingState: Object.assign(Object.assign({}, s.mapUrlLoadingState), { [mapUrl]: progress }) })));
    }
    setPoleVisibility(poleVisibility) {
        this.setState((s) => (Object.assign(Object.assign({}, s), { poleVisibility })));
    }
    setMouseCoordinate(mouseCoordinate) {
        this.setState((s) => (Object.assign(Object.assign({}, s), { mouseCoordinate })));
    }
    setMeasureEnabled(enabled) {
        this.setState((s) => (Object.assign(Object.assign({}, s), { measure: Object.assign(Object.assign({}, s.measure), { enabled, coordinateA: null, coordinateB: null }) })));
    }
    setMeasureRadius(radius) {
        this.setState((s) => (Object.assign(Object.assign({}, s), { measure: Object.assign(Object.assign({}, s.measure), { radius }) })));
    }
    setMeasureCoordinates(coordinateA, coordinateB) {
        this.setState((s) => (Object.assign(Object.assign({}, s), { measure: Object.assign(Object.assign({}, s.measure), { coordinateA, coordinateB }) })));
    }
    setGraticuleVisibility(visible) {
        this.setState((s) => (Object.assign(Object.assign({}, s), { graticule: Object.assign(Object.assign({}, s.graticule), { visible }) })));
    }
    setGraticuleMeridianIntervalDegrees(meridianIntervalDegrees) {
        this.setState((s) => (Object.assign(Object.assign({}, s), { graticule: Object.assign(Object.assign({}, s.graticule), { meridianIntervalDegrees }) })));
    }
    setGraticuleParallelIntervalDegrees(parallelIntervalDegrees) {
        this.setState((s) => (Object.assign(Object.assign({}, s), { graticule: Object.assign(Object.assign({}, s.graticule), { parallelIntervalDegrees }) })));
    }
    setGraticuleColor(color) {
        this.setState((s) => (Object.assign(Object.assign({}, s), { graticule: Object.assign(Object.assign({}, s.graticule), { color }) })));
    }
    setLightIntensity(lightIntensity) {
        this.setState((s) => (Object.assign(Object.assign({}, s), { lightIntensity })));
    }
    setBumpScale(bumpScale) {
        this.setState((s) => (Object.assign(Object.assign({}, s), { bumpScale })));
    }
    setProjectionOptionIndex(optionIndex) {
        this.setState((s) => (Object.assign(Object.assign({}, s), { projection: Object.assign(Object.assign({}, s.projection), { optionIndex }) })));
    }
};
exports.GlobalStore = GlobalStore;
exports.GlobalStore = GlobalStore = __decorate([
    (0, tsyringe_1.singleton)(),
    __metadata("design:paramtypes", [])
], GlobalStore);
exports.globalStore = tsyringe_1.container.resolve(GlobalStore);
exports.useGlobalStore = (() => {
    return exports.globalStore.useState.bind(exports.globalStore);
})();
