import { globalStore, useGlobalStore } from "@/store/GlobalStore";
import React from "react";
import { useTranslation } from "react-i18next";

export const GridSettings = () => {
  const state = useGlobalStore((s) => s.graticule);
  const { t } = useTranslation();

  return (
    <>
      <label>
        <input
          type="checkbox"
          checked={state.visible}
          onChange={(e) => {
            globalStore.setGraticuleVisibility(e.target.checked);
          }}
        />
        {t("gridSettings.showLatLongGrid")}
      </label>
      <br />
      {state.visible && (
        <div id="gridSettings">
          <label>{t("gridSettings.meridiansInterval")}</label>
          <br />
          <input
            type="text"
            id="meridianInt"
            value={state.meridianIntervalDegrees}
            onChange={(e) => {
              globalStore.setGraticuleMeridianIntervalDegrees(parseInt(e.target.value) || 0);
            }}
          />
          <span> degrees</span>
          <br />
          <label>{t("gridSettings.parallelsInterval")}</label>
          <br />
          <input
            type="text"
            id="parallelInt"
            value={state.parallelIntervalDegrees}
            onChange={(e) => {
              globalStore.setGraticuleParallelIntervalDegrees(parseInt(e.target.value) || 0);
            }}
          />
          <span> {t("degrees")}</span>
          <br />
          <label>
            Color:{" "}
            <input
              type="color"
              id="gridColor"
              value={state.color}
              onChange={(e) => {
                globalStore.setGraticuleColor(e.target.value);
              }}
            ></input>
          </label>
        </div>
      )}
    </>
  );
};
