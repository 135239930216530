import React from "react";
import { useEffect } from "react";

export const TreeNodeDeepnessContext = React.createContext(0);

export const useElementFocus = (active: boolean, el: React.RefObject<HTMLElement>) => {
  useEffect(() => {
    if (active && el.current) {
      // TODO: This method call won't work on Safari
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView#browser_compatibility
      el.current.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, [active]);
};
