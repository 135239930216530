import "./Tree.scss";
import React, { useContext, useRef } from "react";
import { TreeNodeDeepnessContext, useElementFocus } from "./Tree.hooks";

const style = (name?: string) => `mm-tree${name || ""}`;

export type TreeProps = {
  children?: React.ReactNode;
};

export const Tree = (props: TreeProps) => {
  return (
    <TreeNodeDeepnessContext.Provider value={0}>
      <div className={style()}>{props.children}</div>
    </TreeNodeDeepnessContext.Provider>
  );
};

export type TreeNodeProps = {
  text: string;
  selected?: boolean;
  onSelect?: () => void;
  expanded?: boolean;
  onExpand?: (v: boolean) => void;
  children?: React.ReactNode;
  progress?: number | null;
};

export const TreeNode = (props: TreeNodeProps) => {
  const el = useRef<HTMLDivElement>(null);
  const deepness = useContext(TreeNodeDeepnessContext);
  useElementFocus(props.selected || false, el);

  const expandable = !!props.children;
  const classes = [
    style("-node"),
    ...(props.selected ? ["is-selected"] : []),
    ...(expandable ? ["is-expandable"] : []),
  ];

  return (
    <>
      <div
        ref={el}
        className={classes.join(" ")}
        onClick={props.onSelect}
        style={{ marginLeft: deepness * 30, ...progressStyle(props.progress) }}
      >
        {expandable && (
          <div
            className={style("-node-plus")}
            onClick={(e) => {
              e.stopPropagation();
              props.onExpand && props.onExpand(!props.expanded);
            }}
          >
            <span>{props.expanded ? "-" : "+"}</span>
          </div>
        )}
        <span>{props.text}</span>
      </div>
      {expandable && props.expanded && (
        <TreeNodeDeepnessContext.Provider value={deepness + 1}>{props.children}</TreeNodeDeepnessContext.Provider>
      )}
    </>
  );
};

const TREE_BASE_BACKGROUND_COLOR = "#aeaeae";
const TREE_DARKENED_BACKGROUND_COLOR = "#aeaeae3d";
const progressStyle = (progress?: number | null) => {
  if (progress == null) {
    return {};
  }

  const percent = Math.round(progress * 100);
  return {
    background: `linear-gradient(90deg, ${TREE_BASE_BACKGROUND_COLOR} 0%, ${TREE_BASE_BACKGROUND_COLOR} ${percent}%, ${TREE_DARKENED_BACKGROUND_COLOR} ${percent}%, ${TREE_DARKENED_BACKGROUND_COLOR} 100%)`,
  };
};
