"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectionController = void 0;
const GlobalStore_1 = require("@/store/GlobalStore");
const ProjectionOptions_1 = require("./ProjectionOptions");
const ProjectionRenderer_1 = require("./ProjectionRenderer");
const Disposable_1 = require("@/utils/Disposable");
const tsyringe_1 = require("tsyringe");
let ProjectionController = class ProjectionController extends Disposable_1.Disposable {
    constructor(projectionRenderer, globalStore) {
        super();
        this.projectionRenderer = projectionRenderer;
        this.globalStore = globalStore;
        this.subscribe(globalStore, (s) => ({ activeView: s.activeView, mapUrls: s.mapUrls, projection: s.projection }), ({ activeView, mapUrls, projection }) => {
            this.projectionRenderer.render(activeView === "projection", mapUrls, ProjectionOptions_1.PROJECTION_OPTIONS[projection.optionIndex]);
        });
    }
};
exports.ProjectionController = ProjectionController;
exports.ProjectionController = ProjectionController = __decorate([
    (0, tsyringe_1.singleton)(),
    __metadata("design:paramtypes", [typeof (_a = typeof ProjectionRenderer_1.ProjectionRenderer !== "undefined" && ProjectionRenderer_1.ProjectionRenderer) === "function" ? _a : Object, typeof (_b = typeof GlobalStore_1.GlobalStore !== "undefined" && GlobalStore_1.GlobalStore) === "function" ? _b : Object])
], ProjectionController);
