import "./StaticPageViewer.scss";
import { GlobalState, useGlobalStore } from "@/store/GlobalStore";
import React from "react";
import { useTranslation } from "react-i18next";

const STATIC_PAGES: Partial<Record<GlobalState["activeView"], string>> = {
  "about-us": "contextMenu.aboutUsText",
  "using-meteomars": "contextMenu.usingMeteoMarsText",
  observing: "contextMenu.observingText",
};

export const StaticPageViewer = () => {
  const { t } = useTranslation();
  const activeView = useGlobalStore((s) => s.activeView);

  if (Object.keys(STATIC_PAGES).indexOf(activeView) === -1) {
    return <></>;
  }

  return (
    <div className="mm-static-page-viewer">
      <div
        className="mm-static-page-viewer-content"
        dangerouslySetInnerHTML={{ __html: t(STATIC_PAGES[activeView]!)! }}
      />
    </div>
  );
};
