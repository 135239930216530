import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { subscribeWithSelector } from "zustand/middleware";

export class BaseStore<TState> {
  private zustandStore;
  constructor(initialState: TState) {
    this.zustandStore = create(subscribeWithSelector<TState>(() => initialState));
  }

  public subscribe<TStateDerivation>(
    selector: (state: TState) => TStateDerivation,
    listener: (selectedState: TStateDerivation, previousSelectedState: TStateDerivation) => void,
  ) {
    return this.zustandStore.subscribe(selector, listener, { fireImmediately: true, equalityFn: shallow });
  }

  public useState<TStateDerivation>(selector: (state: TState) => TStateDerivation) {
    return this.zustandStore(selector);
  }

  public getState() {
    return this.zustandStore.getState();
  }

  protected setState(func: (state: TState) => TState) {
    this.zustandStore.setState(func);
  }
}
