export const eu = {
  translations: {
    language: "Hizkuntza",
    names: {
      es: "Gaztelera",
      en: "Ingelesa",
      eu: "Euskera",
    },
  },
  degrees: "gradu",
  radius: "Radius",
  units: "unitate",
  date: "Data",
  animations: {
    leftToRight: "Ezkerretik eskumara",
    makeGifOf: "Sortu gif honengandik:",
    rightToLeft: "Eskumatik ezkerretara",
    rotatingGlobe: "Planeta biratzen (eguzkia finko)",
    rotatingSun: "Eguzkia biratzen (fixed globe)",
    rotationDirection: "Biraketa Norabidea:",
    sunRotateSpeed: "Eguzkiaren biraketa abiadura:",
  },
  contextMenu: {
    aboutUs: "Nortzuk gara",
    aboutUsText: `<p>
                    MeteoMars-eko irudi globalak <a href="https://mars.nasa.gov/mro/mission/instruments/marci/" target="_blank">MARCI
                    (Mars Color Imager)</a> izeneko tresnak egun berdinean hartutako irudi konbinatuak dira, NASAren
                    <a href="https://mars.nasa.gov/mro/mission/overview/" target="_blank">MRO (Mars Reconnaissance Orbiter)</a> izeneko misioaren barne dagoela.
                    13 irudi kontsekutibo elkartuz egun bakoitzeko 2:00 p.m.-tik gertu dagoen mapa globala osatzea posiblea da.

                    Tresna eta bere irudi hartzeari buruzko zehaztasun gehiago, <a href="https://agupubs.onlinelibrary.wiley.com/doi/full/10.1029/2008JE003315" target="_blank">Mars Reconnaissance
                    Orbiter Mars Color Imager (MARCI): Instrument description, calibration, and performance</a> begiratu.
                  </p>
                  <p>
                     MARCIk hartutako irudiak elkarrekin prozesatu eta konbinatzen dira <a href="https://www.usgs.gov/" target="_blank">USGS</a>-k
                     garatutako <a href="https://isis.astrogeology.usgs.gov/">ISIS</a> toolkit-en tresnei esker.
                  </p>
                  <p>
                    MeteoMars-en <a href="https://pds-imaging.jpl.nasa.gov/volumes/mro.html" target="_blank">Planetary Data System's Cartography and Imaging Science Node</a>-k
                    argitaratutako irudiak prosezatu eta erakusten ditugu.
                  </p>
                  <p>
                     Irudi hauek, planetaren mapa globalak osatzen dituztenak, MeteoMars-en agertzen dira
                     <a href="https://github.com/kurt1288/MapToGlobe" target="_blank ">Maptoglobe</a> proiektuaren interfazeari
                     moldaketa batzuk eginez.
                  </p>
                  <p>
                    Marteren meteorologia ikertzeko tresna hau <a href="https://www.pamplonetario.org/"
                    target="_blank">Pamplona Planetarium</a>-ek, <a href="http:// www.ajax.ehu.es/" target="_blank">EHU-ko
                    Zientzia Planetarien Taldea</a>-k, <a href="https://www.linkedin.com/in/txusordorika/?originalSubdomain=en"
                    target="_blank">Txus Ordorika</a>-k, <a href="https://rodrmoya.github.io/" target="_blank">Rodrigo Moya</a>-k eta
                    <a href="https://sirikon.me/" target="_blank">Carlos Fdez. Llamas</a>-ek  sustatuta eta garatuta dago.
                  </p>`,
    animations: "Animazioak",
    mapProjections: "Mapa Proiekzioak",
    observing: "Begiratzen",
    observingText: `<h1>Zer ikusi dezakegu MeteoMars-en?</h1>
                    <p>
                      MARCI kamerak jasotako irudiek eguraldia ikertzeko aukera zoragarriak eskeintzen dute. Gertaera meteorologiko
                      zein urtaroek eragindako aldaketak esploratu eta begiratu daitezke MeteoMars-ekin.
                    </p>
                    <p>
                      Hauts ekaitzak: hauts ekaitzak planetaren azalean azaltzen diren gertaerak dira. Gertaera hauek arruntak dira
                      Marte-n, eta tamainaren arabera hiru motatan sailkatu daitezke:
                    </p>
                    <div style="text-align:center">
                      <img src="/img/duststorm_20200207.png" alt="Hauts ekaitza" />
                    </div>
                    <ol type="a">
                      <li>
                        Hauts ekaitz lokalak: Ekaitz hauek txikiak dira eta eremu txikiei eragiten diete, orokorrean 1,600,000 km²-ko
                        hedaduratako eremuei. Ekaitz lokalak orduetatik egunetara iraun daitezke eta haize lokalek eremu zehatz batean
                        altsatzen dituzten hautsak osatzen dute. Arruntenak dira eta Marte urteko edozein garaietan gertatu daitezke.
                      </li>
                      <li>
                        Eskualdeko hauts ekaitzak: ekaitz lokalak baino handiagoak dira eta 1,600,000 km²-tik goratako eremuak estali
                        ahal dituzte, sakonuneak edo eskualde osoak, alegia. Eskualdeko ekaitzak egunetatik aste batzuk arte iraun daitezke
                        eta normalean ekaitz lokalen arteko interakzioari esker sortzen dira. Marteko udaberri eda udan gehiago sortzen dira
                        hego hemisferioan.
                      </li>
                      <li>
                        Hauts ekaitz globalak: Marten gertatzen diren hauts ekaitzik handienak eta izugarrizkoenak dira, planeta osoa guztiz
                        edo ia guztiz estaltzera arte. Ekaitz hauek asteetatik hilabeteetako iraupena eduki ahal dute, eta eragin nabarmena
                        eduki dezakete planetaren atmosferan eta kliman. Ekaitz hauek ezohizkoak dira, 3 edo 4 marteko urtetan behin gertatu
                        ahal direla (lurreko 5,5 eta 7,5 urtetan behin gutxi gora behera). Udatik udazkena hasiera bitartean sortu daitezke
                        hego hemisferioan.
                      </li>
                    </ol>
                    <p>
                       Ur hodeiak: Martek ur izoztutaz osatutako hodeiak ditu. Hodei hauek altuera zein itxura ezberdinetakoak
                       izan daitezke, marteko atmosferan dagoen ur lurrina kondentsatu eta gero osaten baitira.
                    </p>
                    <div style="text-align:center">
                      <img src="/img/waterclouds_20200506.png" alt="Ur hodeiak" />
                    </div>
                    <p>
                      Sasoiko antzigar eta izotza: Marteko eskualde hotzagoetan, ur lurrina eta karbono dioxidoa izoztu daitezke
                      lur azalean, antzigar eta izotza agertuz sasoika.
                    </p>
                    <div style="text-align:center">
                      <img src="/img/frost_20200514.png" alt="Sasoi antzigarra" />
                    </div>
                    <p>
                      Hodei orografikoak: mendiek edo beste ezaugarri topografikoek behartuta igotako haize hezeak osatzen
                      ditu hodei hauek. Airea igo ahala, hoztu eta ur lurrina kondentsatzen da, lente formako hodeiak sortuz.
                      Hodei orografikoak fenomeno arrunta da bai Marten zein Lurrean eta zirkulazio atmosferikoan eragin dezake.
                    </p>
                    <div style="text-align:center">
                      <img src="/img/orographiccloud_20190828.png" alt="Hodei Orografikoak" />
                    </div>
                    <p>
                      Grabitate uhinak: Aire dentsitzatearen aldaketak zein ezaugarri topografikoek behartuta mugitzen den aireak
                       sortutako oszilazio atmosferikoak dira. Grabitate uhinek zirkulazio atmosferikoa eta hodei sorrerak aldatu
                       dezakete eta Marteko azalean azaltzen dira hodei uhin-bandak bezala. Uhin hauek grabitatearen indarrak mugitzen
                       ditu eta Lurreko zein Marteko atmosferatan azaltzen dira.
                    </p>
                    <div style="text-align:center">
                      <img src="/img/gravitywaves_20200502.png" alt="Grabitate uhinak" />
                    </div>
                    <p>
                      Poloetako geruza zabaleraren aldaketak: Poloetako geruza Marten, ura zein karbono dioxido izoztuaz osatuta dago
                       , Marteko sasoien arabera aldaketak jasatzen dituzte. Urtaroaren arabera tenperatura eta poloetara heltzen den eguzki
                        argi kopuruak eraginda, sublimazio zein sedimentazio prozesuek sortzen dituzte ezberdintasun hauek.
                      <br/>
                      Marteko neguan tenperatura poloetan bortizki jaisten da eta eguzki argia murrizten da. Honen ondorioz,
                      karbono dioxidoa izozten da eta sedimentatzen da poloetako geruzetan, haien zabalera handituz. Honetaz gain
                      atmosferan egon zitekeen ur lurrina izozten da ere ur izotz geruza haundituz.
                    </p>
                    <div style="text-align:center">
                      <img src="/img/polo1_20190801.png" alt="Poloko geruza neguan" />
                    </div>
                    <p>
                      Marteko udaberri eta udan, tenperaturak poloetan igotzen dira eta eguzkitik heltzen den argia ere bai. Karbono
                      dioxidoa sublimatzen da, solidotik gas bihurtuz, geruza murriztuz. Prosezu hau ur izoztutarekin ere gertatzen da,
                      kopuru urriagotan.
                    </p>
                    <div style="text-align:center">
                      <img src="/img/polo2_20200110.png" alt="Poloko geruza udan" />
                    </div>
                    <p>
                      Poloetako izotz geruza aldaketa gertaera meteorologiko nabarmena da Marten, aire zirkulazio zein
                      klima baldintzatan eragina bait dauka. Marte orbitatzen ari diren sateliteak eta espaziountziek monitorizatzen
                      dituzte aldaketa hauek Marte klima zein bere aldaketak urtean zehar hobeto ulertu ditzagun.
                    </p>
                    <h2>Bibliografia</h2>
                    <p>
                      Hauts ekaitzak:
                      <ul>
                        <li>
                          <cite>
                            Smith, M. D. (2004). Interannual variability in TES atmospheric observations of Mars during 1999-2003. Icarus, 167(1), 148-165.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Kass, D. M., Kleinböhl, A., McCleese, D. J., Schofield, J. T., & Smith, M. D. (2016). Interannual similarity in the Martian
                            atmosphere during the dust storm season. Geophysical Research Letters, 43(12), 6111-6118.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Zurek, R. W., & Martin, L. J. (1993). Interannual variability of planet-encircling dust storms on Mars. Journal of Geophysical Research: Planets, 98(E2), 3247-3259.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Haberle, R. M., Clancy, R. T., Forget, F., Smith, M. D., & Zurek, R. W. (2019). The atmosphere and climate of Mars. Cambridge University Press.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Cantor, B. A., James, P. B., Caplinger, M., & Wolff, M. J. (2001). Martian dust storms: 1999 Mars Orbiter Camera observations. Journal of
                            Geophysical Research: Planets, 106(E10), 23653-23687.
                          </cite>
                        </li>
                      </ul>
                    </p>
                    <p>
                      Ur hodeiak:
                      <ul>
                        <li>
                          <cite>
                            Montmessin, F., Quémerais, E., Bertaux, J. L., Korablev, O., Rannou, P., & Forget, F. (2006). Stellar
                            occultations at UV wavelengths by the SPICAM instrument: Retrieval and analysis of Martian haze profiles.
                            Journal of Geophysical Research: Planets, 111(E10).
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Vincendon, M., Pilorget, C., Gondet, B., Murchie, S., & Bibring, J. P. (2011). New near-IR observations of
                            mesospheric CO2 and H2O clouds on Mars. Journal of Geophysical Research: Planets, 116(E11).
                          </cite>
                        </li>
                      </ul>
                    </p>
                    <p>
                      Sasoiko antzigar eta izotza:
                      <ul>
                        <li>
                          <cite>
                            Martínez, G. M., & Renno, N. O. (2013). Water and brines on Mars: current evidence and implications for
                            MSL. Space Science Reviews, 175(1-4), 29-51.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Piqueux, S., Byrne, S., & Richardson, M. I. (2003). Sublimation of Mars's southern seasonal CO2 cap and the
                            formation of spiders. Journal of Geophysical Research: Planets, 108(E8).
                          </cite>
                        </li>
                      </ul>
                    </p>
                    <p>
                      Hodei orografikoak:
                      <ul>
                        <li>
                          <cite>
                            Spiga, A., Smith, I., Madeleine, J. B., Navarro, T., Forget, F., & Montmessin, F. (2018). Orographic
                            gravity-wave clouds in the Martian lower atmosphere. Nature Geoscience, 11(6), 423-427.
                          </cite>
                        </li>
                      </ul>
                    </p>
                    <p>
                      Grabitate uhinak:
                      <ul>
                        <li>
                          <cite>
                            Heavens, N. G., Kleinböhl, A., Chaffin, M. S., Halekas, J. S., Kass, D. M., Hayne, P. O., ... & Shirley,
                            J. H. (2018). Hydrogen escape from Mars enhanced by deep convection in dust storms. Nature Astronomy, 2(2),
                            126-132.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Pettengill, G. H., Ford, P. G., & Wilt, R. J. (2000). Mars Orbiter Laser Altimeter (MOLA) profiles of Martian
                            “gravity waves”. Geophysical Research Letters, 27(3), 370-372.
                          </cite>
                        </li>
                      </ul>
                    </p>
                    <p>
                      Poloetako geruza hedapen aldaketa :
                      <ul>
                        <li>
                          <cite>
                            Kelly, N. J., Richardson, M. I., & Wilson, R. J. (2006). Modeling the effects of weather on the Mars polar
                            layered deposits. Icarus, 181(1), 44-60.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Thomas, P. C., Malin, M. C., Edgett, K. S., Carr, M. H., Hartmann, W. K., Ingersoll, A. P., ... & Veverka, J.
                            (2000). North-south geological differences between the residual polar caps on Mars. Nature, 404(6774), 161-164.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Smith, D. E., Zuber, M. T., Solomon, S. C., Phillips, R. J., Head, J. W., Garvin, J. B., ... & Neumann, G. A. (1999).
                            The global topography of Mars and implications for surface evolution. Science, 284(5419), 1495-1503.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Buhler, P., Ingersoll, A., Ehlmann, B., Fassett, C., & Head, J. W. (2017). How the martian residual south polar cap
                            develops quasi-circular and heart-shaped pits, troughs, and moats. Icarus, 286, 69-93.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Titus, T. N., Kieffer, H. H., & Christensen, P. R. (2003). Exposed water ice discovered near the south pole of Mars.
                            Science, 299(5609), 1048-1051.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Plaut, J. J., Picardi, G., Safaeinili, A., Ivanov, A. B., Milkovich, S. M., Cicchetti, A., ... & Heggy, E. (2007).
                            Subsurface radar sounding of the south polar layered deposits of Mars. Science, 316(5821), 92-95.
                          </cite>
                        </li>
                      </ul>
                    </p>`,
    options: "Aukerak",
    usingMeteomars: "MeteoMars erabilera",
    usingMeteoMarsText: `
                        <p>
                          <h1>Kontrolak</h1>
                          <p>Ezker-click and arrastratu rotatzeko. Xagu gurpilarekin zoom egiteko.</p>
                        </p>
                        <p>
                          <h1>Polo Marra erakutsi</h1>
                          <p>Polo/axis marra erakusten den ala ez aldatzen du.</p>
                        </p>
                        <p>
                          <h1>Lat/Long Sarea erakutsi</h1>
                          <p>Latitude/longitude sarea planeta gainean erakusten ala ez aldatzen du.</p>
                        </p>
                        <p>
                          <h1>Meridiano tartea</h1>
                          <p>Zenbateko distantziarekin, gradutan, meridianoak (marra bertikalak) agertzen dira.</p>
                        </p>
                        <p>
                          <h1>Paraleloen tartea</h1>
                          <p>Zenbateko distantziarekin, gradutan, paraleloak (marra horizontalak) agertzen dira.</p>
                        </p>
                        <p>
                          <h1>Kolorea</h1>
                          <p>Latitude/longitude sarearen kolorea.</p>
                        </p>
                        <p>
                          <h1>Distantzia neurtu</h1>
                          <p>Ahalbidetzen zaitu bi puntu jartzea mapan haien arteko distantzia neurrituz.</p>
                          <p>Planetaren radioa ezarri distantzia zuzena jasotzeko.</p>
                        </p>
                        <p>
                          <h1>Argi Intentsitatea</h1>
                          <p>Eguzkiaren distira.</p>
                        </p>
                        <p>
                          <h1>Altuera-mapa eskala</h1>
                          <p>Elebazioaren eskala aldatzen du.</p>
                        </p>
                        <p>
                          <h1>Animazioak</h1>
                          <p>Planetaren gif animatuak sortu ditzakezu. Prosezu honek zure ordenagailua erabiltzen du, zure hardwarearen
                          araberako prosezua izanik.</p>
                        </p>
                        <p>
                          <h1>Norabide rotazionala</h1>
                          <p>Kontrolatu planetaren rotazioa gif-a sortzerakoan.</p>
                        </p>
                        <p>
                          <h1>Honen GIF egin...</h1>
                          <p>"Planeta biratu"-k eguzkia finko mantenduko du eta planeta biratu.</p>
                        </p>`,
  },
  gridSettings: {
    meridiansInterval: "Meridiano tartea",
    parallelsInterval: "Paralelo tartea",
    showLatLongGrid: "Lat/Long Sarea erakutsi",
  },
  measureSettings: {
    measureDistance: "Distantzia neurtu",
  },
  optionsMenu: {
    heightmapScale: "Altuera-mapa eskala",
    lightIntensity: "Argi Intentsitatea",
    showPoleLine: "Polo Marra erakutsi",
  },
};
