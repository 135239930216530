export const es = {
  translations: {
    language: "Idioma",
    names: {
      es: "Español",
      en: "Inglés",
      eu: "Euskera",
    },
  },
  degrees: "grados",
  radius: "Radio",
  units: "unidades",
  date: "Fecha",
  animations: {
    leftToRight: "De izquierda a derecha",
    makeGifOf: "Generar gif de:",
    rightToLeft: "De derecha a izquierda",
    rotatingGlobe: "Rotación del globo (sol fijo)",
    rotatingSun: "Rotación del sol (globo fijo)",
    rotationDirection: "Dirección de rotación:",
    sunRotateSpeed: "Velocidad de rotación del sol:",
  },
  contextMenu: {
    aboutUs: "Acerca de",
    aboutUsText: `<p>
                    Los mapas globales que se muestran en MeteoMars son combinaciones de imágenes tomadas para un
                    mismo día por el instrumento <a href="https://mars.nasa.gov/mro/mission/instruments/marci/"
                    target="_blank">MARCI (Mars Color Imager)</a> a bordo de la misión <a
                    href="https://mars.nasa.gov/mro/mission/overview/" target="_blank">MRO (Mars Reconnaissance Orbiter)</a> de
                    NASA. Combinando 13 imágnes consecutivas es posible generar un mapa global que corresponde a la situación del
                    planeta en horas cercanas a las 14:00 h en una misma fecha. Para más conocer más detalles del instrumento y su
                    estrategia de captura de imágenes se puede consultar <a
                    href="https://agupubs.onlinelibrary.wiley.com/doi/full/10.1029/2008JE003315" target="_blank">Mars
                    Reconnaissance Orbiter Mars Color Imager (MARCI): Instrument description, calibration, and performance</a>.
                  </p>
                  <p>
                    Las imágenes obtenidas por MARCI son procesadas y combinadas entre ellas con las aplicaciones disponibles en
                    el paquete de herramientas <a href="https://isis.astrogeology.usgs.gov/">ISIS</a>, desarrollado por la <a
                    href="https://www.usgs.gov/" target="_blank">USGS</a>.
                  </p>
                  <p>
                    En MeteoMars procesamos y mostramos las imágenes disponibles públicamente en el <a
                    href="https://pds-imaging.jpl.nasa.gov/volumes/mro.html" target="_blank">Planetary Data System’s Cartography
                    and Imaging Science Node</a>.
                  </p>
                  <p>
                    Estas imágenes, ya combinadas y procesadas para formar mapas globales del planeta, se visualizan en MeteoMars
                    usando una adaptación de la interface desarrollada para el proyecto <a
                    href="https://github.com/kurt1288/MapToGlobe" target="_blank">Maptoglobe</a>.
                  </p>
                  <p>
                    Esta herramienta para el estudio de la meteorología en Marte está impulsada y desarrollada por el <a
                    href="https://www.pamplonetario.org/" target="_blank">Planetario de Pamplona</a>, el <a
                    href="http://www.ajax.ehu.es/" target="_blank">Grupo de Ciencias Planetarias de la Universidad del País
                    Vasco</a> y por <a href="https://www.linkedin.com/in/txusordorika/?originalSubdomain=es"
                    target="_blank">Txus Ordorika</a>, <a href="https://rodrmoya.github.io/" target="_blank">Rodrigo Moya</a>
                    y <a href="https://sirikon.me/" target="_blank">Carlos Fdez. Llamas</a>.
                  </p>`,
    animations: "Animaciones",
    mapProjections: "Proyecciones de mapa",
    observing: "Observación",
    observingText: `<h1>¿Qué podemos observar en MeteoMars?</h1>
                    <p>
                      Las posibilidades de observación meteorológica que nos ofrecen las imágenes obtenidas por la cámara MARCI son fabulosas.
                      Aquí podrás encontrar algunos ejemplos de eventos meteorológicos y cambios estacionales que se pueden observar y explorar
                      con MeteoMars:
                    </p>
                    <p>
                      Tormentas de polvo: Las tormentas de polvo en Marte son un fenómeno meteorológico que se dan en la superficie del planeta.
                      Este tipo de eventos son comunes en Marte y se pueden clasificar por tamaño en tres categorías:
                    </p>
                    <div style="text-align:center">
                      <img src="/img/duststorm_20200207.png" alt="Tormenta de polvo" />
                    </div>
                    <ol type="a">
                      <li>
                        Tormentas de polvo locales: Estas tormentas son pequeñas y afectan áreas relativamente limitadas, generalmente con una
                        superficie menor a 1.600.000 km². Las tormentas locales pueden durar desde horas hasta varios días y se forman principalmente
                        debido a vientos locales que levantan el polvo en áreas específicas de la superficie marciana. Estas tormentas son las más
                        comunes y pueden ocurrir en cualquier momento del año marciano.
                      </li>
                      <li>
                        Tormentas de polvo regionales: Estas tormentas son más grandes que las tormentas locales y pueden cubrir áreas con con una
                        superficie mayor de 1.600.000 km², como cuencas o regiones enteras. Las tormentas regionales pueden durar varios días o
                        incluso semanas y, a menudo, son el resultado de la interacción de varios sistemas locales de tormentas de polvo. Generalmente
                        se producen durante las estaciones de primavera y verano en el hemisferio sur de Marte.
                      </li>
                      <li>
                        Tormentas de polvo globales: Estas son las tormentas de polvo más grandes y extremas en Marte, cubriendo todo el planeta o
                        casi todo él. Las tormentas planetarias pueden durar desde semanas hasta meses y pueden tener un impacto significativo en la
                        atmósfera y el clima del planeta. Estas tormentas son raras y ocurren aproximadamente una vez cada 3 a 4 años marcianos
                        (aproximadamente 5.5 a 7.5 años terrestres). Generalmente, se producen en el periodo que va desde el verano hasta principios
                        del otoño en el hemisferio sur de Marte.
                      </li>
                    </ol>
                    <p>
                      Nubes de agua: Marte tiene nubes en su atmósfera compuestas principalmente de partículas de hielo de agua. Estas nubes pueden variar
                      en altitud y se forman debido a la condensación del vapor de agua presente en la atmósfera marciana.
                    </p>
                    <div style="text-align:center">
                      <img src="/img/waterclouds_20200506.png" alt="Nubes de agua" />
                    </div>
                    <p>
                      Escarcha y hielo estacionales: En las regiones más frías de Marte, el vapor de agua y el dióxido de carbono pueden congelarse en la
                      superficie, formando escarcha y hielo estacionalmente.
                    </p>
                    <div style="text-align:center">
                      <img src="/img/frost_20200514.png" alt="Escarcha estacional" />
                    </div>
                    <p>
                      Nubes orográficas: Estas nubes se forman cuando el aire húmedo es forzado a elevarse debido a la presencia de montañas u otras
                      características topográficas en la superficie de Marte. A medida que el aire asciende, se enfría y el vapor de agua se condensa,
                      formando nubes en forma de lente sobre las montañas. Las nubes orográficas son un fenómeno común en ambos planetas, la Tierra y
                      Marte, y pueden afectar la circulación atmosférica local.
                    </p>
                    <div style="text-align:center">
                      <img src="/img/orographiccloud_20190828.png" alt="Nubes orográficas" />
                    </div>
                    <p>
                      Ondas de gravedad: Son oscilaciones atmosféricas que se producen cuando el aire en movimiento es perturbado por características
                      topográficas o cambios en la densidad del aire. Las ondas de gravedad pueden afectar la circulación atmosférica y la formación
                      de nubes en Marte, y a menudo se observan en las imágenes de la superficie marciana como bandas onduladas de nubes. Estas ondas
                      son impulsadas por la fuerza de la gravedad y se propagan tanto en la atmósfera terrestre como en la marciana.
                    </p>
                    <div style="text-align:center">
                      <img src="/img/gravitywaves_20200502.png" alt="Ondas de gravedad" />
                    </div>
                    <p>
                      Cambio de tamaño de los casquetes polares: Los casquetes polares en Marte, compuestos principalmente de hielo de agua y dióxido
                      de carbono congelado, experimentan cambios en su tamaño a lo largo de las estaciones marcianas. Estos cambios se deben a los
                      procesos de sublimación y deposición que ocurren en función de las variaciones estacionales de temperatura y la cantidad de
                      luz solar que llega a los polos.
                    </p>
                    <p>
                      Durante el invierno marciano, las temperaturas en los polos descienden significativamente y la cantidad de luz solar disminuye.
                      Como resultado, el dióxido de carbono atmosférico se congela y se deposita en los casquetes polares, aumentando su tamaño. Además,
                      parte del vapor de agua presente en la atmósfera también se congela y se añade a la capa de hielo de agua en los casquetes.
                    </p>
                    <div style="text-align:center">
                      <img src="/img/polo1_20190801.png" alt="Casquete polar en invierno" />
                    </div>
                    <p>
                      En la primavera y el verano marcianos, las temperaturas en los polos aumentan y la cantidad de luz solar también lo hace. Esto
                      provoca que el dióxido de carbono congelado en los casquetes polares se sublime, pasando directamente del estado sólido al gaseoso,
                      reduciendo así el tamaño de los casquetes. Este proceso también ocurre, aunque en menor medida, con el hielo de agua en los casquetes.
                    </p>
                    <div style="text-align:center">
                      <img src="/img/polo2_20200110.png" alt="Casquete polar en verano" />
                    </div>
                    <p>
                      El cambio de tamaño de los casquetes polares es un evento meteorológico importante en Marte, ya que influye en la circulación
                      atmosférica y las condiciones climáticas en todo el planeta. Los satélites y vehículos espaciales en órbita alrededor de Marte
                      estudian y monitorean estos cambios para comprender mejor el clima marciano y su evolución a lo largo del tiempo.
                    </p>
                    <h2>Bibliografía</h2>
                    <p>
                      Tormentas de polvo:
                      <ul>
                        <li>
                          <cite>
                            Smith, M. D. (2004). Interannual variability in TES atmospheric observations of Mars during 1999-2003. Icarus, 167(1), 148-165.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Kass, D. M., Kleinböhl, A., McCleese, D. J., Schofield, J. T., & Smith, M. D. (2016). Interannual similarity in the Martian
                            atmosphere during the dust storm season. Geophysical Research Letters, 43(12), 6111-6118.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Zurek, R. W., & Martin, L. J. (1993). Interannual variability of planet-encircling dust storms on Mars. Journal of Geophysical Research: Planets, 98(E2), 3247-3259.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Haberle, R. M., Clancy, R. T., Forget, F., Smith, M. D., & Zurek, R. W. (2019). The atmosphere and climate of Mars. Cambridge University Press.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Cantor, B. A., James, P. B., Caplinger, M., & Wolff, M. J. (2001). Martian dust storms: 1999 Mars Orbiter Camera observations. Journal of
                            Geophysical Research: Planets, 106(E10), 23653-23687.
                          </cite>
                        </li>
                      </ul>
                    </p>
                    <p>
                      Nubes de hielo de agua:
                      <ul>
                        <li>
                          <cite>
                            Montmessin, F., Quémerais, E., Bertaux, J. L., Korablev, O., Rannou, P., & Forget, F. (2006). Stellar
                            occultations at UV wavelengths by the SPICAM instrument: Retrieval and analysis of Martian haze profiles.
                            Journal of Geophysical Research: Planets, 111(E10).
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Vincendon, M., Pilorget, C., Gondet, B., Murchie, S., & Bibring, J. P. (2011). New near-IR observations of
                            mesospheric CO2 and H2O clouds on Mars. Journal of Geophysical Research: Planets, 116(E11).
                          </cite>
                        </li>
                      </ul>
                    </p>
                    <p>
                      Escarcha y hielo estacionales:
                      <ul>
                        <li>
                          <cite>
                            Martínez, G. M., & Renno, N. O. (2013). Water and brines on Mars: current evidence and implications for
                            MSL. Space Science Reviews, 175(1-4), 29-51.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Piqueux, S., Byrne, S., & Richardson, M. I. (2003). Sublimation of Mars's southern seasonal CO2 cap and the
                            formation of spiders. Journal of Geophysical Research: Planets, 108(E8).
                          </cite>
                        </li>
                      </ul>
                    </p>
                    <p>
                      Nubes orográficas:
                      <ul>
                        <li>
                          <cite>
                            Spiga, A., Smith, I., Madeleine, J. B., Navarro, T., Forget, F., & Montmessin, F. (2018). Orographic
                            gravity-wave clouds in the Martian lower atmosphere. Nature Geoscience, 11(6), 423-427.
                          </cite>
                        </li>
                      </ul>
                    </p>
                    <p>
                      Ondas de gravedad
                      <ul>
                        <li>
                          <cite>
                            Heavens, N. G., Kleinböhl, A., Chaffin, M. S., Halekas, J. S., Kass, D. M., Hayne, P. O., ... & Shirley,
                            J. H. (2018). Hydrogen escape from Mars enhanced by deep convection in dust storms. Nature Astronomy, 2(2),
                            126-132.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Pettengill, G. H., Ford, P. G., & Wilt, R. J. (2000). Mars Orbiter Laser Altimeter (MOLA) profiles of Martian
                            “gravity waves”. Geophysical Research Letters, 27(3), 370-372.
                          </cite>
                        </li>
                      </ul>
                    </p>
                    <p>
                      Cambio de tamaño de los casquetes polares en Marte:
                      <ul>
                        <li>
                          <cite>
                            Kelly, N. J., Richardson, M. I., & Wilson, R. J. (2006). Modeling the effects of weather on the Mars polar
                            layered deposits. Icarus, 181(1), 44-60.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Thomas, P. C., Malin, M. C., Edgett, K. S., Carr, M. H., Hartmann, W. K., Ingersoll, A. P., ... & Veverka, J.
                            (2000). North-south geological differences between the residual polar caps on Mars. Nature, 404(6774), 161-164.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Smith, D. E., Zuber, M. T., Solomon, S. C., Phillips, R. J., Head, J. W., Garvin, J. B., ... & Neumann, G. A. (1999).
                            The global topography of Mars and implications for surface evolution. Science, 284(5419), 1495-1503.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Buhler, P., Ingersoll, A., Ehlmann, B., Fassett, C., & Head, J. W. (2017). How the martian residual south polar cap
                            develops quasi-circular and heart-shaped pits, troughs, and moats. Icarus, 286, 69-93.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Titus, T. N., Kieffer, H. H., & Christensen, P. R. (2003). Exposed water ice discovered near the south pole of Mars.
                            Science, 299(5609), 1048-1051.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Plaut, J. J., Picardi, G., Safaeinili, A., Ivanov, A. B., Milkovich, S. M., Cicchetti, A., ... & Heggy, E. (2007).
                            Subsurface radar sounding of the south polar layered deposits of Mars. Science, 316(5821), 92-95.
                          </cite>
                        </li>
                      </ul>
                    </p>`,
    options: "Opciones",
    usingMeteomars: "Cómo usar MeteoMars",
    usingMeteoMarsText: `
                        <p>
                          <h1>Controles</h1>
                          <p>Botón izquierdo del ratón y arrastrar para rotar. Rueda de scroll para hacer zoom.</p>
                        </p>
                        <p>
                          <h1>Mostrar línea del polo</h1>
                          <p>Determina si el polo/eje es mostrado o no.</p>
                        </p>
                        <p>
                          <h1>Mostrar cuadrícula Lat/Long</h1>
                          <p>Determina si se muestra la rejilla en el globo o no.</p>
                        </p>
                        <p>
                          <h1>Intervalo meridianos</h1>
                          <p>Distancia, en grados, entre los meridianos (líneas verticales).</p>
                        </p>
                        <p>
                          <h1>Intervalo paralelos</h1>
                          <p>Distancia, en grados, entre los paralelos (líneas horizontales).</p>
                        </p>
                        <p>
                          <h1>Color</h1>
                          <p>Color de la rejilla de latitud/longitud.</p>
                        </p>
                        <p>
                          <h1>Medir distancia</h1>
                          <p>Permite pinchar puntos en el globo y calcular la distancia entre dos puntos.</p>
                          <p>Hay que especificar el radio del globo para obtener la distancia correcta.</p>
                        </p>
                        <p>
                          <h1>Intensidad de la luz</h1>
                          <p>Brillo del sol.</p>
                        </p>
                        <p>
                          <h1>Escala del mapa de alturas</h1>
                          <p>Cambia la escala de la elevación.</p>
                        </p>
                        <p>
                          <h1>Animaciones</h1>
                          <p>Aquí se pueden crear gifs animados del globo. Este proceso usa su ordenador para realizar la tarea, lo que
                          significa que la velocidad del proceso depende del hardware de su ordenador.</p>
                        </p>
                        <p>
                          <h1>Dirección de rotación</h1>
                          <p>Controla la velocidad de giro del globo cuando se crea un gif.</p>
                        </p>
                        <p>
                          <h1>Generar gif de...</h1>
                          <p>La opción "Rotación del globo" dejará el sol fijo y moverá el globo.</p>
                        </p>`,
  },
  gridSettings: {
    meridiansInterval: "Intervalo meridianos",
    parallelsInterval: "Intervalo paralelos",
    showLatLongGrid: "Mostrar cuadrícula Lat/Long",
  },
  measureSettings: {
    measureDistance: "Medir distancia",
  },
  optionsMenu: {
    heightmapScale: "Escala del mapa de alturas",
    lightIntensity: "Intensidad de la luz",
    showPoleLine: "Mostrar línea del polo",
  },
};
