import "./CurrentMapInfo.scss";
import { GlobalState, useGlobalStore } from "@/store/GlobalStore";
import React from "react";
import { useTranslation } from "react-i18next";

const VISIBLE_IN_VIEWS: GlobalState["activeView"][] = ["globe", "projection"];

export const CurrentMapInfo = () => {
  const { t } = useTranslation();
  const [activeView, mapInfo, mouseCoordinate] = useGlobalStore((s) => [s.activeView, s.mapInfo, s.mouseCoordinate]);

  if (VISIBLE_IN_VIEWS.indexOf(activeView) === -1) return <></>;

  const mouseCoordinateText = mouseCoordinate?.geo
    ? [`Lat: ${mouseCoordinate.geo.lat.toFixed(5)}`, `Long: ${mouseCoordinate.geo.long.toFixed(5)}`]
    : [];
  const mapInfoText =
    mapInfo != null
      ? [`${t("date")}: ${mapInfo.date}`, ...(mapInfo.imageId ? [`ImageId: ${mapInfo.imageId}`] : [])]
      : [];
  return (
    <div className="mm-current-map-info">
      {mouseCoordinateText.join(", ")}
      <br />
      {mapInfoText.join(" - ")}
    </div>
  );
};
