"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectionRenderer = void 0;
const tsyringe_1 = require("tsyringe");
const ProjectionContext_1 = require("./ProjectionContext");
let ProjectionRenderer = class ProjectionRenderer {
    constructor(projectionContext) {
        this.projectionContext = projectionContext;
    }
    render(visible, mapUrls, projectionOption) {
        return __awaiter(this, void 0, void 0, function* () {
            this.projectionContext.area.style.display = visible ? "block" : "none";
            const canvas = this.projectionContext.canvas;
            canvas.width = window.innerWidth - 150;
            canvas.height = (window.innerWidth - 150) / 2;
            const ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            if (!visible || mapUrls.length == 0)
                return;
            const scale = (canvas.width + 1) / 2 / Math.PI;
            const projection = projectionOption.projection({ scale, projectionCanvas: canvas });
            const imagesPromises = mapUrls.map((mapUrl) => {
                return new Promise((resolve) => {
                    const image = new Image();
                    image.onload = () => {
                        resolve(image);
                    };
                    image.src = mapUrl;
                });
            });
            const images = yield Promise.all(imagesPromises);
            for (const image of images) {
                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
            }
            const dx = canvas.width;
            const dy = canvas.height;
            const sourceData = ctx.getImageData(0, 0, dx, dy).data;
            const target = ctx.createImageData(dx, dy);
            const targetData = target.data;
            for (var y = 0, i = -1; y < dy; ++y) {
                for (var x = 0; x < dx; ++x) {
                    var p = projection.invert([x, y]);
                    if (!p) {
                        targetData[++i] = 0;
                        targetData[++i] = 0;
                        targetData[++i] = 0;
                        targetData[++i] = 0;
                        continue;
                    }
                    var λ = p[0], φ = p[1];
                    if (λ > 180 || λ < -180 || φ > 90 || φ < -90) {
                        i += 4;
                        continue;
                    }
                    var q = (((((90 - φ) / 180) * dy) | 0) * dx + ((((180 + λ) / 360) * dx) | 0)) << 2;
                    targetData[++i] = sourceData[q];
                    targetData[++i] = sourceData[++q];
                    targetData[++i] = sourceData[++q];
                    targetData[++i] = 255;
                }
            }
            ctx.clearRect(0, 0, dx, dy);
            ctx.putImageData(target, 0, 0);
        });
    }
};
exports.ProjectionRenderer = ProjectionRenderer;
exports.ProjectionRenderer = ProjectionRenderer = __decorate([
    (0, tsyringe_1.singleton)(),
    __metadata("design:paramtypes", [typeof (_a = typeof ProjectionContext_1.ProjectionContext !== "undefined" && ProjectionContext_1.ProjectionContext) === "function" ? _a : Object])
], ProjectionRenderer);
