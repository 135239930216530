export const en = {
  translations: {
    language: "Language",
    names: {
      es: "Spanish",
      en: "English",
      eu: "Basque",
    },
  },
  degrees: "degrees",
  radius: "Radius",
  units: "units",
  date: "Date",
  animations: {
    leftToRight: "Left to Right",
    makeGifOf: "Make gif of:",
    rightToLeft: "Right to Left",
    rotatingGlobe: "Rotating globe (fixed sun)",
    rotatingSun: "Rotating sun (fixed globe)",
    rotationDirection: "Rotation direction:",
    sunRotateSpeed: "Sun Rotate Speed:",
  },
  contextMenu: {
    aboutUs: "About Us",
    aboutUsText: `<p>
                    The global maps shown combination in MeteoMars are images taken from the same day by the instrument
                    <a href="https://mars.nasa.gov/mro/mission/instruments/marci/" target="_blank">MARCI (Mars Color Imager)</a>
                    onboard the mission <a href="https://mars.nasa.gov/mro/mission/overview/" target="_blank">MRO (Mars Reconnaissance Orbiter)</a> of NASA.
                    Combining 13 consecutive images it is possible to generate a global map that corresponds
                    to the situation of the planet in hours close to 2:00 p.m. on the same date. For more details on the instrument
                    and its image capture strategy, see <a href="https://agupubs.onlinelibrary.wiley.com/doi/full/10.1029/2008JE003315" target="_blank">Mars Reconnaissance
                    Orbiter Mars Color Imager (MARCI): Instrument description, calibration, and performance</a>.
                  </p>
                  <p>
                    The images obtained by MARCI are processed and combined with each other with the applications available in the
                    <a href="https://isis.astrogeology.usgs.gov/">ISIS</a> toolkit, developed by the <a href="https://www.usgs.gov/" target="_blank">USGS</a>.
                  </p>
                  <p>
                    At MeteoMars we process and display publicly available imagery at the <a href="https://pds-imaging.jpl.nasa.gov/volumes/mro.html"
                    target="_blank">Planetary Data System's Cartography and Imaging Science Node </a>.
                  </p>
                  <p>
                    These images, already combined and processed to form global maps of the planet, are displayed in MeteoMars using
                    an adaptation of the interface developed for the project <a href="https://github.com/kurt1288/MapToGlobe"
                    target="_blank ">Maptoglobe</a>.
                  </p>
                  <p>
                    This tool for the study of meteorology on Mars is promoted and developed by the <a href="https://www.pamplonetario.org/"
                    target="_blank">Pamplona Planetarium</a>, the <a href="http: // www.ajax.ehu.es/" target="_blank">Planetary Sciences Group of the
                    University of the Basque Country</a> and by <a href="https://www.linkedin.com/in/txusordorika/?originalSubdomain=en"
                    target="_blank">Txus Ordorika</a>, <a href="https://rodrmoya.github.io/" target="_blank">Rodrigo Moya</a>
                    and <a href="https://sirikon.me/" target="_blank">Carlos Fdez. Llamas</a>.
                  </p>`,
    animations: "Animations",
    mapProjections: "Map Projections",
    observing: "Observing",
    observingText: `<h1>What can we observe in MeteoMars?</h1>
                    <p>
                      The weather observation possibilities offered by the images obtained from the MARCI camera are fabulous. Here you
                      will find some examples of meteorological events and seasonal changes that can be observed and explored with MeteoMars.
                    </p>
                    <p>
                      Dust storms: Dust storms on Mars are a meteorological phenomenon that occur on the surface of the planet. This type
                      of event is common on Mars and can be classified by size into three categories:
                    </p>
                    <div style="text-align:center">
                      <img src="/img/duststorm_20200207.png" alt="Dust storm" />
                    </div>
                    <ol type="a">
                      <li>
                        Local dust storms: These storms are small and affect relatively limited areas, generally with a surface area of
                        less than 1,600,000 km². Local storms can last from hours to several days and are mainly formed due to local winds
                        lifting dust in specific areas of the Martian surface. These storms are the most common and can occur at any time
                        of the Martian year.
                      </li>
                      <li>
                        Regional dust storms: These storms are larger than local storms and can cover areas with a surface area greater than
                        1,600,000 km², such as basins or entire regions. Regional storms can last several days or even weeks and are often the
                        result of the interaction of several local dust storm systems. They generally occur during the spring and summer
                        seasons in the southern hemisphere of Mars.
                      </li>
                      <li>
                        Global dust storms: These are the largest and most extreme dust storms on Mars, covering the entire planet or almost
                        all of it. Planetary storms can last from weeks to months and can have a significant impact on the atmosphere and
                        climate of the planet. These storms are rare and occur approximately once every 3 to 4 Martian years (approximately
                        5.5 to 7.5 Earth years). They generally occur during the period from summer to early autumn in the southern hemisphere
                        of Mars.
                      </li>
                    </ol>
                    <p>
                      Water clouds: Mars has clouds in its atmosphere composed mainly of water ice particles. These clouds can vary in
                      altitude and form due to the condensation of water vapor present in the Martian atmosphere.
                    </p>
                    <div style="text-align:center">
                      <img src="/img/waterclouds_20200506.png" alt="Water clouds" />
                    </div>
                    <p>
                      Seasonal frost and ice: In the colder regions of Mars, water vapor and carbon dioxide can freeze on the surface,
                      forming frost and ice seasonally.
                    </p>
                    <div style="text-align:center">
                      <img src="/img/frost_20200514.png" alt="Seasonal frost" />
                    </div>
                    <p>
                      Orographic clouds: These clouds form when moist air is forced to rise due to the presence of mountains or other
                      topographic features on the surface of Mars. As the air rises, it cools and water vapor condenses, forming
                      lens-shaped clouds above the mountains. Orographic clouds are a common phenomenon on both Earth and Mars and can
                      affect local atmospheric circulation.
                    </p>
                    <div style="text-align:center">
                      <img src="/img/orographiccloud_20190828.png" alt="Orographic clouds" />
                    </div>
                    <p>
                      Gravity waves: These are atmospheric oscillations that occur when moving air is disturbed by topographic features
                      or changes in air density. Gravity waves can affect atmospheric circulation and cloud formation on Mars and are
                      often observed in images of the Martian surface as undulating bands of clouds. These waves are driven by the force
                      of gravity and propagate in both Earth's and Mars' atmospheres.
                    </p>
                    <div style="text-align:center">
                      <img src="/img/gravitywaves_20200502.png" alt="Gravity waves" />
                    </div>
                    <p>
                      Change in size of the polar caps: The polar caps on Mars, composed mainly of water ice and frozen carbon dioxide,
                      experience changes in size throughout the Martian seasons. These changes are due to the processes of sublimation
                      and deposition that occur based on seasonal variations in temperature and the amount of sunlight reaching the poles.
                      <br/>
                      During the Martian winter, temperatures at the poles drop significantly and the amount of sunlight decreases. As a
                      result, atmospheric carbon dioxide freezes and deposits on the polar caps, increasing their size. Additionally,
                      some of the water vapor present in the atmosphere also freezes and adds to the water ice layer on the caps.
                    </p>
                    <div style="text-align:center">
                      <img src="/img/polo1_20190801.png" alt="Polar cap in winter" />
                    </div>
                    <p>
                      In Martian spring and summer, temperatures at the poles increase and the amount of sunlight also does. This causes
                      the frozen carbon dioxide in the polar caps to sublimate, going directly from the solid to the gaseous state, thus
                      reducing the size of the caps. This process also occurs, albeit to a lesser extent, with the water ice in the caps.
                    </p>
                    <div style="text-align:center">
                      <img src="/img/polo2_20200110.png" alt="Polar cap in summer" />
                    </div>
                    <p>
                      The change in size of the polar caps is an important meteorological event on Mars, as it influences atmospheric
                      circulation and climate conditions throughout the planet. Satellites and spacecraft orbiting Mars study and monitor
                      these changes to better understand Martian climate and its evolution over time.
                    </p>
                    <h2>Bibliography</h2>
                    <p>
                      Dust storms:
                      <ul>
                        <li>
                          <cite>
                            Smith, M. D. (2004). Interannual variability in TES atmospheric observations of Mars during 1999-2003. Icarus, 167(1), 148-165.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Kass, D. M., Kleinböhl, A., McCleese, D. J., Schofield, J. T., & Smith, M. D. (2016). Interannual similarity in the Martian
                            atmosphere during the dust storm season. Geophysical Research Letters, 43(12), 6111-6118.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Zurek, R. W., & Martin, L. J. (1993). Interannual variability of planet-encircling dust storms on Mars. Journal of Geophysical Research: Planets, 98(E2), 3247-3259.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Haberle, R. M., Clancy, R. T., Forget, F., Smith, M. D., & Zurek, R. W. (2019). The atmosphere and climate of Mars. Cambridge University Press.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Cantor, B. A., James, P. B., Caplinger, M., & Wolff, M. J. (2001). Martian dust storms: 1999 Mars Orbiter Camera observations. Journal of
                            Geophysical Research: Planets, 106(E10), 23653-23687.
                          </cite>
                        </li>
                      </ul>
                    </p>
                    <p>
                      Water clouds:
                      <ul>
                        <li>
                          <cite>
                            Montmessin, F., Quémerais, E., Bertaux, J. L., Korablev, O., Rannou, P., & Forget, F. (2006). Stellar
                            occultations at UV wavelengths by the SPICAM instrument: Retrieval and analysis of Martian haze profiles.
                            Journal of Geophysical Research: Planets, 111(E10).
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Vincendon, M., Pilorget, C., Gondet, B., Murchie, S., & Bibring, J. P. (2011). New near-IR observations of
                            mesospheric CO2 and H2O clouds on Mars. Journal of Geophysical Research: Planets, 116(E11).
                          </cite>
                        </li>
                      </ul>
                    </p>
                    <p>
                      Seasonal frost and ice:
                      <ul>
                        <li>
                          <cite>
                            Martínez, G. M., & Renno, N. O. (2013). Water and brines on Mars: current evidence and implications for
                            MSL. Space Science Reviews, 175(1-4), 29-51.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Piqueux, S., Byrne, S., & Richardson, M. I. (2003). Sublimation of Mars's southern seasonal CO2 cap and the
                            formation of spiders. Journal of Geophysical Research: Planets, 108(E8).
                          </cite>
                        </li>
                      </ul>
                    </p>
                    <p>
                      Orographic clouds:
                      <ul>
                        <li>
                          <cite>
                            Spiga, A., Smith, I., Madeleine, J. B., Navarro, T., Forget, F., & Montmessin, F. (2018). Orographic
                            gravity-wave clouds in the Martian lower atmosphere. Nature Geoscience, 11(6), 423-427.
                          </cite>
                        </li>
                      </ul>
                    </p>
                    <p>
                      Gravity waves:
                      <ul>
                        <li>
                          <cite>
                            Heavens, N. G., Kleinböhl, A., Chaffin, M. S., Halekas, J. S., Kass, D. M., Hayne, P. O., ... & Shirley,
                            J. H. (2018). Hydrogen escape from Mars enhanced by deep convection in dust storms. Nature Astronomy, 2(2),
                            126-132.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Pettengill, G. H., Ford, P. G., & Wilt, R. J. (2000). Mars Orbiter Laser Altimeter (MOLA) profiles of Martian
                            “gravity waves”. Geophysical Research Letters, 27(3), 370-372.
                          </cite>
                        </li>
                      </ul>
                    </p>
                    <p>
                      Change in size of the polar caps:
                      <ul>
                        <li>
                          <cite>
                            Kelly, N. J., Richardson, M. I., & Wilson, R. J. (2006). Modeling the effects of weather on the Mars polar
                            layered deposits. Icarus, 181(1), 44-60.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Thomas, P. C., Malin, M. C., Edgett, K. S., Carr, M. H., Hartmann, W. K., Ingersoll, A. P., ... & Veverka, J.
                            (2000). North-south geological differences between the residual polar caps on Mars. Nature, 404(6774), 161-164.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Smith, D. E., Zuber, M. T., Solomon, S. C., Phillips, R. J., Head, J. W., Garvin, J. B., ... & Neumann, G. A. (1999).
                            The global topography of Mars and implications for surface evolution. Science, 284(5419), 1495-1503.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Buhler, P., Ingersoll, A., Ehlmann, B., Fassett, C., & Head, J. W. (2017). How the martian residual south polar cap
                            develops quasi-circular and heart-shaped pits, troughs, and moats. Icarus, 286, 69-93.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Titus, T. N., Kieffer, H. H., & Christensen, P. R. (2003). Exposed water ice discovered near the south pole of Mars.
                            Science, 299(5609), 1048-1051.
                          </cite>
                        </li>
                        <li>
                          <cite>
                            Plaut, J. J., Picardi, G., Safaeinili, A., Ivanov, A. B., Milkovich, S. M., Cicchetti, A., ... & Heggy, E. (2007).
                            Subsurface radar sounding of the south polar layered deposits of Mars. Science, 316(5821), 92-95.
                          </cite>
                        </li>
                      </ul>
                    </p>`,
    options: "Options",
    usingMeteomars: "Using MeteoMars",
    usingMeteoMarsText: `
                        <p>
                          <h1>Controls</h1>
                          <p>Left-click and drag to rotate. Scrollwheel to zoom.</p>
                        </p>
                        <p>
                          <h1>Show Pol Line</h1>
                          <p>Toggles whether a pole/axis line is shown.</p>
                        </p>
                        <p>
                          <h1>Show Lat/Long Grid</h1>
                          <p>Toggles a latitude/longitude grid overlay on the globe.</p>
                        </p>
                        <p>
                          <h1>Meridians interval</h1>
                          <p>How far apart, in degrees, the meridians (vertical lines) are.</p>
                        </p>
                        <p>
                          <h1>Parallels interval</h1>
                          <p>How far apart, in degrees, the parallels (horizontal lines) are.</p>
                        </p>
                        <p>
                          <h1>Color</h1>
                          <p>The color of the latitude/longitude grid.</p>
                        </p>
                        <p>
                          <h1>Measure distance</h1>
                          <p>Enables you to click to points on the globe and have the distance measured.</p>
                          <p>Specify the radius of the globe to get the correct distance.</p>
                        </p>
                        <p>
                          <h1>Light Intensity</h1>
                          <p>The brightness of the sun.</p>
                        </p>
                        <p>
                          <h1>Heightmap Scale</h1>
                          <p>Changes the scale of the elevation.</p>
                        </p>
                        <p>
                          <h1>Animations</h1>
                          <p>Here you can create animated gifs of the globe. This process uses your computer to do the necessary work, so the
                          speed is dependent on your computer's hardware.</p>
                        </p>
                        <p>
                          <h1>Rotation direction</h1>
                          <p>Control which way the globe is spun when creating a gif.</p>
                        </p>
                        <p>
                          <h1>Make gif of...</h1>
                          <p>"Rotating globe" will keep the sun fixed and rotate the globe.</p>
                        </p>`,
  },
  gridSettings: {
    meridiansInterval: "Meridians interval",
    parallelsInterval: "Parallels interval",
    showLatLongGrid: "Show Lat/Long Grid",
  },
  measureSettings: {
    measureDistance: "Measure distance",
  },
  optionsMenu: {
    heightmapScale: "Heightmap Scale",
    lightIntensity: "Light Intensity",
    showPoleLine: "Show Pole Line",
  },
};
