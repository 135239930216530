import "./ContextMenu.scss";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { GlobalState, globalStore, useGlobalStore } from "@/store/GlobalStore";
import { OptionsMenu } from "../OptionsMenu";
import { LanguageSelector } from "../LanguageSelector/LanguageSelector";

type ContextMenuState = {
  activeFloatingMenu: typeof OptionsMenu | typeof AnimationsMenu | null;
};

export const ContextMenu = () => {
  const { t } = useTranslation();
  const activeView = useGlobalStore((s) => s.activeView);

  const [state, setState] = useState<ContextMenuState>({
    activeFloatingMenu: null,
  });

  const toggleActiveView = (newActiveView: GlobalState["activeView"]) => {
    if (activeView === newActiveView) {
      globalStore.setActiveView("globe");
    } else {
      globalStore.setActiveView(newActiveView);
    }
  };

  const toggleFloatingMenu = (newFloatingMenu: ContextMenuState["activeFloatingMenu"]) => {
    if (activeView !== "globe") return;
    if (state.activeFloatingMenu === newFloatingMenu) {
      setState((s) => ({ ...s, activeFloatingMenu: null }));
    } else {
      setState((s) => ({ ...s, activeFloatingMenu: newFloatingMenu }));
    }
  };

  const logoSrc = `img/logo_meteo${CONFIG.logoVariant}.png`;

  return (
    <>
      <img className="meteomarsLogo" src={logoSrc} alt="" />
      <Menu>
        <MenuItem
          displayName={t("contextMenu.options")}
          onClick={() => toggleFloatingMenu(OptionsMenu)}
          active={state.activeFloatingMenu === OptionsMenu && activeView === "globe"}
          disabled={activeView !== "globe"}
        />
        <MenuItem
          displayName={t("contextMenu.animations")}
          onClick={() => toggleFloatingMenu(AnimationsMenu)}
          active={state.activeFloatingMenu === AnimationsMenu && activeView === "globe"}
          disabled={activeView !== "globe"}
        />
        <MenuItem
          displayName={t("contextMenu.mapProjections")}
          onClick={() => toggleActiveView("projection")}
          active={activeView === "projection"}
        />
        {CONFIG.information && (
          <MenuItem
            displayName={t("contextMenu.aboutUs")}
            onClick={() => toggleActiveView("about-us")}
            active={activeView === "about-us"}
          />
        )}
        <MenuItem
          displayName={t("contextMenu.usingMeteomars")}
          onClick={() => toggleActiveView("using-meteomars")}
          active={activeView === "using-meteomars"}
        />
        {CONFIG.information && (
          <MenuItem
            displayName={t("contextMenu.observing")}
            onClick={() => toggleActiveView("observing")}
            active={activeView === "observing"}
          />
        )}
      </Menu>

      {(() => {
        if (activeView !== "globe") return;
        if (state.activeFloatingMenu != null) {
          const FloatingMenuContent = state.activeFloatingMenu;
          return (
            <FloatingMenu>
              <FloatingMenuContent />
            </FloatingMenu>
          );
        }
      })()}

      <LanguageSelector />
    </>
  );
};

const Menu = ({ children }: { children?: React.ReactNode }) => {
  return <ul id="globalMenu">{children}</ul>;
};

const MenuItem = (props: { displayName: string; active?: boolean; disabled?: boolean; onClick?: () => void }) => {
  const classes = [...(props.active ? ["is-active"] : []), ...(props.disabled ? ["is-disabled"] : [])];
  return (
    <li onClick={props.onClick} className={classes.join(" ")}>
      {props.displayName}
    </li>
  );
};

const FloatingMenu = ({ children }: { children?: React.ReactNode }) => {
  return (
    <div id="contextMenu" style={{ display: "block" }}>
      <div style={{ display: "block" }}>{children}</div>
    </div>
  );
};

const AnimationsMenu = () => {
  const { t } = useTranslation();
  const [direction, setDirection] = useState<"left-to-right" | "right-to-left">("left-to-right");

  return (
    <div className="mm-animations-menu">
      <div className="mm-animations-menu-title">{t("animations.rotationDirection")}</div>
      <label>
        <input
          type="radio"
          name="rotateDir"
          value="-1"
          checked={direction === "left-to-right"}
          onChange={(e) => e.target.checked && setDirection("left-to-right")}
        />
        {t("animations.leftToRight")}
      </label>
      <br />
      <label>
        <input
          type="radio"
          name="rotateDir"
          value="1"
          checked={direction === "right-to-left"}
          onChange={(e) => e.target.checked && setDirection("right-to-left")}
        />
        {t("animations.rightToLeft")}
      </label>
      <div className="mm-animations-menu-title">{t("animations.makeGifOf")}</div>
      <input type="button" value={t("animations.rotatingGlobe").toString()} className="makeGif" />
      <br />
      <div id="additionalAnim">
        <input type="button" value={t("animations.rotatingSun").toString()} className="makeGif" />
        <br />
        <p>{t("animations.sunRotateSpeed")}</p>
        <input type="range" id="sunRotateSpeed" value="0.05" min="0.04" max="0.07" step="0.001" />
        <output htmlFor="sunRotateSpeed" className="generatePropOutput">
          0.050
        </output>
      </div>
    </div>
  );
};
