import "./ImageSelector.scss";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useMapLoadProgress, useVerticalArrows } from "./ImageSelector.hooks";
import { Tree, TreeNode } from "../Tree/Tree";
import {
  ImageSelectorControllerContext,
  ImageSelectorIndex,
  useImageSelectorController,
  useImageSelectorControllerFactory,
} from "./ImageSelector.controller";
import { CollectionFragment, CollectionMap } from "./ImageSelector.api";
import { GlobalState, useGlobalStore } from "@/store/GlobalStore";

const style = (name?: string) => `mm-image-selector${name || ""}`;

const VISIBLE_IN_VIEWS: GlobalState["activeView"][] = ["globe", "projection"];

export const ImageSelector = () => {
  const controller = useImageSelectorControllerFactory();
  const multiselect = controller.useState((s) => s.multiselect);
  const activeView = useGlobalStore((s) => s.activeView);

  useEffect(() => {
    controller.fetchMaps();
  }, []);

  useVerticalArrows((n) => {
    if (controller.getState().multiselect) return;
    controller.shiftMap(n);
  });

  return (
    <ImageSelectorControllerContext.Provider value={controller}>
      <div className={style()} style={VISIBLE_IN_VIEWS.indexOf(activeView) >= 0 ? {} : { display: "none" }}>
        <div className={style("-sidebar")}>
          <div className={style("-list")}>
            <div className={style("-list-header")}>
              <input
                type="checkbox"
                id="imageselector-multiselect"
                checked={multiselect}
                onChange={(e) => {
                  controller.toggleMultiselect();
                }}
              />
              <label htmlFor="imageselector-multiselect"> Multiselect</label>
            </div>
            <Tree>
              <MapTreeNodeList />
            </Tree>
          </div>
          <Metadata />
        </div>
      </div>
    </ImageSelectorControllerContext.Provider>
  );
};

const MapTreeNodeList = () => {
  const controller = useImageSelectorController();
  const maps = controller.useState((s) => s.maps);
  const selectedIndexes = controller.useState((s) => s.selectedIndexes);
  return (
    <>
      {maps.map((map, i) => (
        <MapTreeNode
          key={map.id}
          map={map}
          mapIndex={i}
          active={!!selectedIndexes.find(([mi, fi]) => mi === i && fi === null)}
        />
      ))}
    </>
  );
};

const MapTreeNode = memo((props: { map: CollectionMap; mapIndex: number; active: boolean }) => {
  const controller = useImageSelectorController();
  const [expanded, setExpanded] = useState(false);
  const idx: ImageSelectorIndex = [props.mapIndex, null];
  const progress = useMapLoadProgress(idx);
  return (
    <TreeNode
      text={props.map.id}
      progress={progress}
      selected={props.active}
      onSelect={useCallback(() => controller.toggleSelectedIndex(idx), [props.mapIndex])}
      expanded={expanded}
      onExpand={useCallback((v: boolean) => setExpanded(v), [])}
    >
      <FragmentTreeNodeList map={props.map} mapIndex={props.mapIndex} />
    </TreeNode>
  );
});

const FragmentTreeNodeList = (props: { map: CollectionMap; mapIndex: number }) => {
  const controller = useImageSelectorController();
  const fragments = controller.useState((s) => s.fragments[props.map.id] || []);
  const selectedIndexes = controller.useState((s) => s.selectedIndexes);

  useEffect(() => {
    controller.fetchFragments(props.map.id);
  }, [props.map.id]);

  return (
    <>
      {fragments.map((fragment, i) => (
        <FragmentTreeNode
          key={fragment.id}
          fragment={fragment}
          mapIndex={props.mapIndex}
          fragmentIndex={i}
          selected={!!selectedIndexes.find(([mi, fi]) => mi === props.mapIndex && fi === i)}
        />
      ))}
    </>
  );
};

const FragmentTreeNode = memo(
  (props: { fragment: CollectionFragment; mapIndex: number; fragmentIndex: number; selected: boolean }) => {
    const controller = useImageSelectorController();
    const idx: ImageSelectorIndex = [props.mapIndex, props.fragmentIndex];
    const progress = useMapLoadProgress(idx);
    return (
      <TreeNode
        text={props.fragment.id}
        progress={progress}
        selected={props.selected}
        onSelect={() => controller.toggleSelectedIndex(idx)}
      />
    );
  },
);

const Metadata = () => {
  const controller = useImageSelectorController();
  const selectedIndexes = controller.useState((s) => s.selectedIndexes);
  if (selectedIndexes.length != 1) return null;

  const [mapIndex, fragmentIndex] = selectedIndexes[0];
  if (fragmentIndex == null) return null;
  const { maps, fragments } = controller.getState();

  const data = fragments[maps[mapIndex].id]![fragmentIndex].metadata;
  if (data == null) return null;

  const keysToDisplay = [
    "PRODUCT_ID",
    "IMAGE_TIME",
    "CENTER_LONGITUDE",
    "CENTER_LATITUDE",
    "SPACECRAFT_ALTITUDE",
    "SOLAR_LONGITUDE",
    "LOCAL_TIME",
    "ORBIT_NUMBER",
  ];

  return (
    <div className={style("-meta")}>
      <ul className={style("-meta-items")}>
        {keysToDisplay.map((k) => (
          <li key={k} className={style("-meta-item")}>
            <span className={style("-meta-item-key")}>{k}</span>
            <span className={style("-meta-item-value")}>{data[k]}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
