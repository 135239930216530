import { BaseStore } from "@/store/BaseStore";

export class Disposable {
  constructor() {}

  private deferredCallbacks: (() => void)[] = [];

  protected subscribe<TState, TStateDerivation>(
    store: BaseStore<TState>,
    selector: (state: TState) => TStateDerivation,
    listener: (selectedState: TStateDerivation, previousSelectedState: TStateDerivation) => void,
  ) {
    this.defer(store.subscribe(selector, listener));
  }

  protected defer(cb: () => void) {
    this.deferredCallbacks.push(cb);
  }

  public dispose() {
    for (const cb of this.deferredCallbacks) {
      cb();
    }
  }
}
