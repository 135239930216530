"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useImageSelectorController = exports.useImageSelectorControllerFactory = exports.ImageSelectorControllerContext = exports.ImageSelectorController = void 0;
const BaseStore_1 = require("@/store/BaseStore");
const GlobalStore_1 = require("@/store/GlobalStore");
const react_1 = __importStar(require("react"));
const react_2 = require("react");
const ImageSelector_api_1 = require("./ImageSelector.api");
const tsyringe_1 = require("tsyringe");
let ImageSelectorController = class ImageSelectorController extends BaseStore_1.BaseStore {
    constructor(globalStore) {
        super({
            multiselect: false,
            selectedIndexes: [],
            maps: [],
            fragments: {},
        });
        this.globalStore = globalStore;
    }
    fetchMaps() {
        return __awaiter(this, void 0, void 0, function* () {
            const maps = yield (0, ImageSelector_api_1.getMaps)("default");
            this.setState((s) => (Object.assign(Object.assign({}, s), { maps })));
        });
    }
    fetchFragments(mapId) {
        return __awaiter(this, void 0, void 0, function* () {
            const fragments = yield (0, ImageSelector_api_1.getFragments)("default", mapId);
            this.setState((s) => (Object.assign(Object.assign({}, s), { fragments: Object.assign(Object.assign({}, s.fragments), { [mapId]: fragments }) })));
        });
    }
    toggleMultiselect() {
        this.setState((s) => (Object.assign(Object.assign({}, s), { multiselect: !s.multiselect })));
        if (!this.getState().multiselect) {
            this.setState((s) => (Object.assign(Object.assign({}, s), { selectedIndexes: s.selectedIndexes.length > 0 ? [s.selectedIndexes[s.selectedIndexes.length - 1]] : [] })));
        }
        this.updateGlobalStore();
    }
    toggleSelectedIndex(selectedIndex) {
        const { multiselect, selectedIndexes } = this.getState();
        const existingSelectedIndexPos = selectedIndexes.findIndex(([mi, fi]) => mi === selectedIndex[0] && fi === selectedIndex[1]);
        if (multiselect) {
            if (existingSelectedIndexPos === -1) {
                this.setState((s) => (Object.assign(Object.assign({}, s), { selectedIndexes: [...s.selectedIndexes, selectedIndex] })));
            }
            else {
                this.setState((s) => (Object.assign(Object.assign({}, s), { selectedIndexes: [
                        ...selectedIndexes.slice(0, existingSelectedIndexPos),
                        ...selectedIndexes.slice(existingSelectedIndexPos + 1),
                    ] })));
            }
        }
        else {
            if (existingSelectedIndexPos === -1) {
                this.setState((s) => (Object.assign(Object.assign({}, s), { selectedIndexes: [selectedIndex] })));
            }
            else {
                this.setState((s) => (Object.assign(Object.assign({}, s), { selectedIndexes: [] })));
            }
        }
        this.updateGlobalStore();
    }
    setSelectedIndex(selectedIndex) {
        this.setState((s) => (Object.assign(Object.assign({}, s), { selectedIndexes: [selectedIndex] })));
        this.updateGlobalStore();
    }
    shiftMap(positions) {
        const { selectedIndexes, maps, fragments } = this.getState();
        if (selectedIndexes.length == 0) {
            this.setSelectedIndex([0, null]);
            return;
        }
        const [mapIndex, fragmentIndex] = selectedIndexes[0];
        if (fragmentIndex != null) {
            const currentFragments = fragments[maps[mapIndex].id] || [];
            this.setSelectedIndex([mapIndex, between(0, fragmentIndex + positions, currentFragments.length - 1)]);
            return;
        }
        if (mapIndex != null) {
            this.setSelectedIndex([between(0, mapIndex + positions, maps.length - 1), null]);
            return;
        }
        this.setSelectedIndex([0, null]);
    }
    updateGlobalStore() {
        const { selectedIndexes } = this.getState();
        const urls = [];
        for (const selectedIndex of selectedIndexes) {
            const map = this.getMapUrl(selectedIndex);
            map && urls.push(map);
        }
        this.globalStore.setMap(urls, this.getMapInfo());
    }
    getMapUrl(idx) {
        const { maps, fragments } = this.getState();
        const [mapIndex, fragmentIndex] = idx;
        if (fragmentIndex != null) {
            const fragment = fragments[maps[mapIndex].id][fragmentIndex];
            return getMapUrl(fragment.image);
        }
        if (mapIndex != null) {
            return getMapUrl(maps[mapIndex].image);
        }
        return null;
    }
    getMapInfo() {
        const { selectedIndexes, maps, fragments } = this.getState();
        if (selectedIndexes.length != 1)
            return null;
        const [selectedMapIndex, selectedFragmentIndex] = selectedIndexes[0];
        if (selectedMapIndex == null)
            return null;
        return {
            date: maps[selectedMapIndex].id,
            imageId: selectedFragmentIndex != null ? fragments[maps[selectedMapIndex].id][selectedFragmentIndex].id : null,
        };
    }
};
exports.ImageSelectorController = ImageSelectorController;
exports.ImageSelectorController = ImageSelectorController = __decorate([
    (0, tsyringe_1.singleton)(),
    __metadata("design:paramtypes", [typeof (_a = typeof GlobalStore_1.GlobalStore !== "undefined" && GlobalStore_1.GlobalStore) === "function" ? _a : Object])
], ImageSelectorController);
exports.ImageSelectorControllerContext = react_1.default.createContext(null);
const useImageSelectorControllerFactory = () => {
    const [controller] = (0, react_2.useState)(() => {
        const childContainer = tsyringe_1.container.createChildContainer();
        return childContainer.resolve(ImageSelectorController);
    });
    return controller;
};
exports.useImageSelectorControllerFactory = useImageSelectorControllerFactory;
const useImageSelectorController = () => {
    return (0, react_1.useContext)(exports.ImageSelectorControllerContext);
};
exports.useImageSelectorController = useImageSelectorController;
const between = (min, value, max) => {
    return Math.max(min, Math.min(max, value));
};
const getMapUrl = (path) => {
    return window.location.origin + window.location.pathname + "data/" + path;
};
