import { calculateDistance } from "@/graphics/globe/GlobeMeasurements";
import { Coordinate, GlobalState, globalStore, useGlobalStore } from "@/store/GlobalStore";
import React from "react";
import { useTranslation } from "react-i18next";

export const MeasureSettings = () => {
  const state = useGlobalStore((s) => s.measure);
  const { t } = useTranslation();

  return (
    <>
      <button
        onClick={(e) => {
          globalStore.setMeasureEnabled(!state.enabled);
        }}
      >
        {t("measureSettings.measureDistance")}
      </button>
      {state.enabled && (
        <div id="measureBox">
          <br />
          <span id="distance">{calculateText(state)}</span>
        </div>
      )}
      <br />
    </>
  );
};

const calculateText = (measure: GlobalState["measure"]) => {
  if (measure.coordinateA === null) {
    return <>Click two spots on the globe</>;
  }
  if (measure.coordinateB === null) {
    return <>Click a 2nd spot</>;
  }
  const distance = calculateDistance(measure);

  return (
    <>
      Distance: {distance ? distance.toFixed(2) : "--"} km
      <br />
      {renderGeo("Point A", measure.coordinateA, 2)}
      {renderGeo("Point B", measure.coordinateB, 2)}
    </>
  );
};

const renderGeo = (title: string, coordinate: Coordinate, precission: number) => {
  if (!coordinate.geo) return <></>;
  return (
    <>
      {title}: {[coordinate.geo.lat.toFixed(precission), coordinate.geo.long.toFixed(precission)].join(", ")}
      <br />
    </>
  );
};
