import { useGlobalStore } from "@/store/GlobalStore";
import { useEffect, useRef } from "react";
import { ImageSelectorIndex, useImageSelectorController } from "./ImageSelector.controller";

export const useVerticalArrows = (cb: (n: number) => void) => {
  useKeyboard(["ArrowUp", "ArrowDown"] as const, (e) => {
    if (e === "ArrowUp") cb(-1);
    if (e === "ArrowDown") cb(1);
  });
};

function useKeyboard<T extends readonly string[]>(eventsToCapture: T, cb: (event: T[number]) => void) {
  const cbRef = useRef(cb);
  cbRef.current = cb;

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (eventsToCapture.indexOf(e.key) >= 0) {
        e.preventDefault();
        e.stopPropagation();
        cbRef.current(e.key);
      }
    };
    document.addEventListener("keyup", handler);
    () => document.removeEventListener("keyup", handler);
  }, []);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (eventsToCapture.indexOf(e.key) >= 0) {
        e.preventDefault();
        e.stopPropagation();
      }
    };
    document.addEventListener("keydown", handler);
    () => document.removeEventListener("keydown", handler);
  }, []);
}

export function useMapLoadProgress(idx: ImageSelectorIndex) {
  const controller = useImageSelectorController();
  return useGlobalStore((s) => {
    const mapUrl = controller.getMapUrl(idx);
    if (mapUrl == null) return null;
    return s.mapUrlLoadingState[mapUrl];
  });
}
