"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobeController = void 0;
const THREE = __importStar(require("three"));
const GlobalStore_1 = require("@/store/GlobalStore");
const GlobeRenderer_1 = require("./GlobeRenderer");
const tsyringe_1 = require("tsyringe");
const Disposable_1 = require("@/utils/Disposable");
let GlobeController = class GlobeController extends Disposable_1.Disposable {
    constructor(globalStore, globeRenderer) {
        super();
        this.globalStore = globalStore;
        this.globeRenderer = globeRenderer;
        this.textureLoader = new AjaxTextureLoader();
        this.heightMapTexturePromise = this.loadTexture(this.globalStore.getState().heightmapUrl);
        this.subscribe(globalStore, (s) => s.activeView, (activeView) => {
            globeRenderer.setVisible(activeView === "globe");
        });
        this.subscribe(globalStore, (s) => s.mapUrls, (mapUrls) => {
            this.loadMaps(mapUrls);
        });
        this.subscribe(globalStore, (s) => s.poleVisibility, (poleVisibility) => globeRenderer.setPoleLineVisibility(poleVisibility));
        this.subscribe(globalStore, (s) => s.graticule, (graticule) => globeRenderer.setGraticuleProperties(graticule));
        this.subscribe(globalStore, (s) => s.lightIntensity, (lightIntensity) => globeRenderer.setLightIntensity(lightIntensity));
        this.subscribe(globalStore, (s) => s.bumpScale, (bumpScale) => globeRenderer.setHeightMapBumpScale(bumpScale));
    }
    loadMaps(mapUrls) {
        return __awaiter(this, void 0, void 0, function* () {
            const mapTextures = yield Promise.all(mapUrls.map((mu) => this.loadTexture(mu)));
            const heightMapTexture = yield this.heightMapTexturePromise;
            this.globeRenderer.setGlobeTextures({
                mapTextures,
                heightMapTexture,
                bumpScale: this.globalStore.getState().bumpScale,
            });
            return mapTextures;
        });
    }
    loadTexture(imageUrl) {
        return new Promise((resolve, reject) => {
            this.globalStore.setMapUrlLoadingState(imageUrl, 0);
            this.textureLoader.load(imageUrl, (texture) => {
                this.globalStore.setMapUrlLoadingState(imageUrl, null);
                resolve(texture);
            }, (e) => {
                this.globalStore.setMapUrlLoadingState(imageUrl, Math.min(e.loaded / e.total, 0.95));
                console.log(imageUrl, e.loaded / e.total);
            }, (err) => {
                this.globalStore.setMapUrlLoadingState(imageUrl, null);
                console.log(err);
                reject(new Error(`GlobeController: Error while loading texture from ${imageUrl}: ${err}`));
            });
        });
    }
};
exports.GlobeController = GlobeController;
exports.GlobeController = GlobeController = __decorate([
    (0, tsyringe_1.singleton)(),
    __metadata("design:paramtypes", [typeof (_a = typeof GlobalStore_1.GlobalStore !== "undefined" && GlobalStore_1.GlobalStore) === "function" ? _a : Object, typeof (_b = typeof GlobeRenderer_1.GlobeRenderer !== "undefined" && GlobeRenderer_1.GlobeRenderer) === "function" ? _b : Object])
], GlobeController);
// Based off this: https://github.com/mrdoob/three.js/issues/10439#issuecomment-293260145
class AjaxTextureLoader {
    constructor() {
        this.cache = THREE.Cache;
        this.cache.enabled = true;
        this.textureLoader = new THREE.TextureLoader();
        this.fileLoader = new THREE.FileLoader();
        this.fileLoader.setResponseType("blob");
    }
    load(url, onLoad, onProgress, onError) {
        /**
         * The cache is currently storing a Blob, but we need to cast it to an Image
         * or else it won't work as a texture. TextureLoader won't do this automatically.
         */
        const cacheImage = (data) => {
            if (data instanceof HTMLImageElement) {
                loadImageAsTexture();
                return;
            }
            // ObjectURLs should be released as soon as is safe, to free memory
            const objUrl = URL.createObjectURL(data);
            const image = document.createElementNS("http://www.w3.org/1999/xhtml", "img");
            image.onload = () => {
                this.cache.add(url, image);
                URL.revokeObjectURL(objUrl);
                document.body.removeChild(image);
                loadImageAsTexture();
            };
            image.src = objUrl;
            image.style.visibility = "hidden";
            document.body.appendChild(image);
        };
        const loadImageAsTexture = () => {
            this.textureLoader.load(url, onLoad, () => { }, onError);
        };
        this.fileLoader.load(url, cacheImage, onProgress, onError);
    }
}
