import "reflect-metadata";
import React from "react";
import * as three from "three";
import { createRoot } from "react-dom/client";
import { GlobeController } from "./graphics/globe/GlobeController";
import { GlobeMeasurements } from "./graphics/globe/GlobeMeasurements";
import { GlobeRenderer } from "./graphics/globe/GlobeRenderer";
import { ProjectionController } from "./graphics/projection/ProjectionController";
import { PROJECTION_OPTIONS } from "./graphics/projection/ProjectionOptions";
import { ProjectionRenderer } from "./graphics/projection/ProjectionRenderer";
import { GlobalStore } from "./store/GlobalStore";
import { CurrentMapInfo } from "./ui/CurrentMapInfo/CurrentMapInfo";
import { ContextMenu } from "./ui/ContextMenu/ContextMenu";
import { GlobeContext } from "./graphics/globe/GlobeContext";
import { ProjectionContext } from "./graphics/projection/ProjectionContext";
import { ImageSelector } from "./ui/ImageSelector/ImageSelector";
import "./translations";
import { container } from "tsyringe";
import { StaticPageViewer } from "./ui/StaticPageViewer/StaticPageViewer";

declare global {
  const d3: any;
  interface Window {
    THREE: typeof three;
    V2: unknown;
  }
  const CONFIG: {
    logoVariant: string;
    polarScale: number;
    radius: number;
    heightMap: boolean;
    information: boolean;
  };
}

window.THREE = three;

window.V2 = {
  PROJECTION_OPTIONS,
  globalStore: container.resolve(GlobalStore),
  CurrentMapInfo,
  ContextMenu,
  ImageSelector,
  StaticPageViewer,
  renderGlobe: (canvas: HTMLCanvasElement) => {
    const globeContainer = container.createChildContainer();
    globeContainer.registerInstance(GlobeContext, new GlobeContext(canvas));
    return {
      globeRenderer: globeContainer.resolve(GlobeRenderer),
      globeController: globeContainer.resolve(GlobeController),
      globeMeasurements: globeContainer.resolve(GlobeMeasurements),
    };
  },
  renderProjection: (area: HTMLDivElement, canvas: HTMLCanvasElement) => {
    const projectionContainer = container.createChildContainer();
    projectionContainer.registerInstance(ProjectionContext, new ProjectionContext(area, canvas));
    return {
      projectionRenderer: projectionContainer.resolve(ProjectionRenderer),
      projectionController: projectionContainer.resolve(ProjectionController),
    };
  },
  renderUI: (el: HTMLElement, component: () => JSX.Element) => {
    const root = createRoot(el);
    root.render(React.createElement(component));
  },
};
