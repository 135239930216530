export type ProjectionOption = {
  name: string;
  projection: (args: { scale: number; projectionCanvas: HTMLCanvasElement }) => {
    invert: (args: [number, number]) => [number, number];
  };
  default?: boolean;
};

export const PROJECTION_OPTIONS: ProjectionOption[] = [
  //{name: "Aitoff", projection: ({ scale, projectionCanvas }) => d3.geo.aitoff().scale(scale).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  //{name: "Albers", projection: ({ scale, projectionCanvas }) => d3.geo.albers().scale(scale).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  {
    name: "August",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .august()
        .scale(scale * 0.4)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Baker",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .baker()
        .scale(scale * 0.7)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Boggs",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .boggs()
        .scale(scale * 1.05)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Bonne",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .bonne()
        .scale(scale * 0.75)
        .translate([projectionCanvas.width / 2, (projectionCanvas.height + 180) / 2]),
  },
  //{name: "Bromley", projection: ({ scale, projectionCanvas }) => d3.geo.bromley().scale(scale).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  //{name: "Collignon", projection: ({ scale, projectionCanvas }) => d3.geo.collignon().scale(scale*.62).translate([projectionCanvas.width/2, (projectionCanvas.height+100)/2])},
  {
    name: "Craster Parabolic",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .craster()
        .scale(scale)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Eckert I",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .eckert1()
        .scale(scale)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  //{name: "Eckert II", projection: ({ scale, projectionCanvas }) => d3.geo.eckert2().scale(scale).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  {
    name: "Eckert III",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .eckert3()
        .scale(scale * 1.15)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Eckert IV",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .eckert4()
        .scale(scale * 1.181)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Eckert V",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .eckert5()
        .scale(scale * 1.12)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Eckert VI",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .eckert6()
        .scale(scale * 1.13)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Eisenlohr",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .eisenlohr()
        .scale(scale * 0.37)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Equirectangular (Plate Carrée)",
    default: true,
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .equirectangular()
        .scale(scale)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Fahey",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .fahey()
        .scale(scale * 0.865)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Gall Stereographic",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .cylindricalStereographic()
        .parallel(45)
        .scale(scale * 0.9)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Goode Homolosine",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .homolosine()
        .scale(scale)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Ginzburg IV",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .ginzburg4()
        .scale(scale * 0.94)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Ginzburg V",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .ginzburg5()
        .scale(scale * 0.94)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  //{name: "Ginzburg VI", projection: ({ scale, projectionCanvas }) => d3.geo.ginzburg6().scale(scale*.8).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  //{name: "Ginzburg VIII", projection: ({ scale, projectionCanvas }) => d3.geo.ginzburg8().scale(scale).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  //{name: "Ginzburg IX", projection: ({ scale, projectionCanvas }) => d3.geo.ginzburg9().scale(scale*.8).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  //{name: "Gringorten", projection: ({ scale, projectionCanvas }) => d3.geo.gringorten().scale(scale).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  //{name: "Guyou", projection: ({ scale, projectionCanvas }) => d3.geo.guyou().scale(scale*.6).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  //{name: "Hammer", projection: ({ scale, projectionCanvas }) => d3.geo.hammer().scale(scale*1.1).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  //{name: "Hill", projection: ({ scale, projectionCanvas }) => d3.geo.hill().scale(scale).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  {
    name: "Kavrayskiy VII",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .kavrayskiy7()
        .scale(scale)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Lagrange",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .lagrange()
        .scale(scale * 0.785)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  //{name: "Lambert cylindrical equal-area", projection: ({ scale, projectionCanvas }) => d3.geo.cylindricalEqualArea().scale(scale).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  //{name: "Larrivée", projection: ({ scale, projectionCanvas }) => d3.geo.larrivee().scale(scale*.6).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  {
    name: "Laskowski",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .laskowski()
        .scale(scale * 0.85)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Loximuthal",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .loximuthal()
        .scale(scale)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  //{name: "Mercator", projection: ({ scale, projectionCanvas }) => d3.geo.mercator().scale(scale*.5).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  //{name: "Miller", projection: ({ scale, projectionCanvas }) => d3.geo.miller().scale(scale*.68).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  //{name: "McBryde–Thomas Flat-Polar Parabolic", projection: ({ scale, projectionCanvas }) => d3.geo.mtFlatPolarParabolic().scale(scale*1.07).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  {
    name: "McBryde-Thomas Flat-Polar Quartic",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .mtFlatPolarQuartic()
        .scale(scale * 1.181)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "McBryde-Thomas Flat-Polar Sinusoidal",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .mtFlatPolarSinusoidal()
        .scale(scale * 1.09)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Mollweide",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .mollweide()
        .scale(scale * 1.11)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Natural Earth",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .naturalEarth()
        .scale(scale * 1.1)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  //{name: "Nell–Hammer", projection: ({ scale, projectionCanvas }) => d3.geo.nellHammer().scale(scale).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  //{name: "Polyconic", projection: ({ scale, projectionCanvas }) => d3.geo.polyconic().scale(scale*.5).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  {
    name: "Rectangular Polyconic",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .rectangularPolyconic()
        .scale(scale * 0.82)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Robinson",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .robinson()
        .scale(scale)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Sinusoidal",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .sinusoidal()
        .scale(scale)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  //{name: "Sinu-Mollweide", projection: ({ scale, projectionCanvas }) => d3.geo.sinuMollweide().scale(scale).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  {
    name: "Times",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .times()
        .scale(scale * 0.925)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Van der Grinten",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .vanDerGrinten()
        .scale(scale * 0.5)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Van der Grinten II",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .vanDerGrinten2()
        .scale(scale * 0.5)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  //{name: "Van der Grinten III", projection: ({ scale, projectionCanvas }) => d3.geo.vanDerGrinten3().scale(scale*.5).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  //{name: "Van der Grinten IV", projection: ({ scale, projectionCanvas }) => d3.geo.vanDerGrinten4().scale(scale).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  {
    name: "Wagner IV",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .wagner4()
        .scale(scale * 1.155)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Wagner VI",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .wagner6()
        .scale(scale)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  //{name: "Wagner VII", projection: ({ scale, projectionCanvas }) => d3.geo.wagner7().scale(scale).translate([projectionCanvas.width/2, projectionCanvas.height/2])},
  {
    name: "Winkel Tripel",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo
        .winkel3()
        .scale(scale)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Waterman Butterfly",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo.polyhedron
        .waterman()
        .scale(scale * 0.7)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 2]),
  },
  {
    name: "Gnomonic Butterfly",
    projection: ({ scale, projectionCanvas }) =>
      d3.geo.polyhedron
        .butterfly()
        .scale(scale * 0.62)
        .translate([projectionCanvas.width / 2, projectionCanvas.height / 1.35]),
  },
];
export const DEFAULT_PROJECTION_OPTION_INDEX = PROJECTION_OPTIONS.findIndex((p) => p.default);
