import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en, es, eu } from "./resources";

const DEFAULT_LANGUAGE = "en";
const RESOURCES = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  eu: {
    translation: eu,
  },
};
export const LANGUAGES = Object.keys(RESOURCES);

i18n.use(initReactI18next).init({
  resources: RESOURCES,
  lng: DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
